<template>

    <v-data-table disable-pagination 
        :headers="headers"
        :items="offices"
        :loading="loading"
        :hide-default-footer="true"
        :hide-default-header="false"
    >

        <template v-slot:[`item.date_${index-1}`]="{ item }" v-for="index in 14">
            <v-tooltip bottom :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="item['date_'+(index-1)].seats - item['date_'+(index-1)].reserved == 0" v-bind="attrs" v-on="on">
                        $chair-full
                    </v-icon>
                    <v-icon v-else size="30" :color='icon(item["date_"+(index-1)])' v-bind="attrs" v-on="on">
                        mdi-seat
                    </v-icon>
                </template>
                <span>{{ tooltip(item["date_"+(index-1)]) }}</span>
            </v-tooltip>


        </template>

        <template v-slot:[`item.icon`]="{ item }">
            <v-icon size="32">{{ item.icon }}</v-icon>
        </template>

        <template v-slot:[`item.name`]="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                </template>
                <span>{{ item.company }}, {{ item.time }}</span>
            </v-tooltip>
        </template>

        <template slot="no-data">
            Please join an office to see the availibility schedule here
        </template>                

    </v-data-table>

</template>

<script>

export default {

    props: [ "currentDate" ],
    
    data() {
        return {
            headers: [],
            offices: [],
            loading: true
        }
    },

    created() {
        this.loadOffices();
    },

    methods: {

        tooltip(item) {
            let seats = (item.seats - item.reserved);
            
            if (seats == 0) return "No seats available";

            return seats + " seat(s) available";
        },

        icon(item) {
            let part = 1 - item.reserved / item.seats;

            if (part >= 0.5) return "green";
            if (part < 0.5 && part >= 0.3) return "yellow";
            return "red";
        },

        dateFormat(date) {
            return date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate();
        },

        updateSeats(office, index, value) {

            for (let i=0; i<this.offices.length; i++) {

                if (this.offices[i].oid == office) {
                    this.offices[i]["date_" + index].reserved += value; 
                    break;
                }
            }
        
        },
        
        async loadOffices() {

            this.loading = true;

            //Create table headers
            let headers = [
            {
                text: "",
                value: "filler1",
                width: "70",
                filterable: false, 
                sortable: false
            },
            {
                text: "",
                value: "icon",
                width: "60",
                filterable: false, 
                sortable: false
            },
            {
                text: "",
                align: "start",
                value: "name",
                filterable: false, 
                sortable: false
            }];

            //let weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

            //let date = new Date();
            //date.setDate(this.currentDate.getDate());

            for (let i=0; i<=13; i++) {

                //let fullDate = date.getDate() + "." + (date.getMonth()+1) + " " + weekDays[date.getDay()];
                
                headers.push({ text: "", width: "58", value: "date_" + i, filterable: false, sortable: false, align: "center" });

                //date.setDate(date.getDate()+1);
            }

            //Load office availability
            let response = await fetch(process.env.VUE_APP_API + "/office/availability", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    date: this.dateFormat(this.currentDate)
                })
            });

            let data = await response.json();

            this.headers = headers;
            this.offices = [];

            for (let office of data) {

                let row = {
                    name: office.office,
                    company: office.company,
                    oid: office.oid,
                    icon: 'mdi-domain',
                    time: office.time
                };

                for (let i=0; i<14; i++) {
                    row['date_'+i] = {
                        value: office.oid,
                        ... office.schedule[i]
                    }
                }

                this.offices.push(row);

            }

            this.loading = false;

        }

    }

}
</script>

<style scoped>
::v-deep td, ::v-deep th {
    padding: 0 !important;
}

::v-deep th {
    border-bottom: thin dashed #000 !important;
    height: 0px !important;
}

</style>