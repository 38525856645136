<template>

	<v-container>

		<v-app-bar app height="80">
			<v-container class="py-0 px-0 px-sm-2 fill-height">
				<router-link to="#" class="d-flex align-center text-decoration-none mr-2">
					<img src="@/assets/images/wo-logo.png" alt="" height="48" class="mr-1">
					<span class="font-weight-black text-uppercase">
						WunderOffice
					</span>
				</router-link>

				<div class="d-none d-md-block">
<!--
					<v-btn v-for="(item, index) in menu" :key="index" text :to="item.link" class="mx-1">
						{{ item.title }}
					</v-btn>
-->
				</div>

				<v-spacer></v-spacer>

				<router-link to="/de/index">
					<v-btn density="compact" icon>
						<v-icon>$flag-germany</v-icon>
					</v-btn>
				</router-link>

				<LoginPopup :reset="showReset" />
				<RegisterPopup ref="signUpButton" />

			</v-container>
		</v-app-bar>


		<!--
		<v-container class="py-6">
			<v-row>
				<v-col cols="12" md="6" lg="7">
					<h1 class="text-h4 text-sm-h3 text-lg-h1 mt-xl-4">Home + Office <span class="primary--text">transparent planen</span></h1>
					<h2 class="text-h6 text-sm-h5 secondary--text mt-4">Reserviere deinen Sitzplatz im Office & plane mit deinen Kollegen wann ihr erreichbar seid</h2>


					<div class="mt-4 mt-xl-10">

-->
<!--
						<v-responsive class="mt-8" max-width="600">

							<div class="d-flex flex-column flex-sm-row w-full">
								<v-text-field
									outlined
									solo
									label="deine Email"
									class="mr-2"
								/>


								<v-btn x-large color="secondary" min-width="180">Erhalte mehr Infos</v-btn>
							</div>

						</v-responsive>
-->

<!--


					</div>
				</v-col>
				
				<v-col cols="12" md="6" lg="5" class="d-none d-md-block">
					<v-img class="rounded-lg" :aspect-ratio="1" src="@/assets/images/hero.jpg" alt="" />
				</v-col>
			</v-row>
		</v-container>
	-->



<!--Home & Office auf einen Blick transparent planen, 
-->

	<v-container class="py-6 py-lg-12 text-center">
    <h1 class="text-h4 text-sm-h3 text-md-h2 text-lg-h1 mt-md-6">Home & Office <span class="primary--text">at a glance</span></h1>
    <h2 class="text-h6 text-sm-h5 text--lighten-2 mt-4 ">With the easiest & most affordable Webtool on the market</h2>
	<h2 class="text-h6 text-sm-h5 text--lighten-2 mt-4 "></h2>
    <v-img :src="require('@/assets/images/Landingpage_Screenshot_1_Eng.png')" alt="" class="rounded elevation-0 mb-6"> </v-img>


	
  </v-container>

		<v-sheet>

			<div class="text-center">
				<div class="text-uppercase font-weight-bold body-2 primary--text mb-2">The benefits for all Office Workers</div>
				<h2 class="text-h3 text-lg-h2">Be ready for the hybrid office setup</h2>

				<v-responsive max-width="1200" class="mx-auto">
					<div class="text-h6 text-lg-h5 mt-4">The best of both worlds: focused at home + face to face in office</div>
				</v-responsive>
			</div>

			<v-container class="py-4 py-lg-8 mt-5">
				<v-row>
					<v-col
						v-for="(item, i) in features"
						:key="i"
						cols="12"
						sm="6"
						lg="3"
					>

						<v-responsive max-width="300" class="mx-auto text-center">
							<div class="mb-2">
							<v-icon class="text-h1" color="primary">{{ item.icon }}</v-icon>
							</div>
							<div class="text-h5 mt-3">{{ item.title }}</div>
							<div class="font-weight-regular mt-2">{{ item.description }}</div>
						</v-responsive>

					</v-col>
				</v-row>
			</v-container>

		</v-sheet>

		<v-sheet color="transparent">
			<v-container class="py-4 py-lg-8">
				<div class="text-center">
					<div class="text-uppercase font-weight-bold body-2 primary--text mb-2">The benefits for HR & Office Management</div>
					<h2 class="text-h3 text-lg-h2">Make your work environment ready for the future of hybrid work</h2>

					<v-responsive max-width="1200" class="mx-auto">
						<div class="text-h6 text-lg-h5 mt-4">Save costs + increase productivity & team spirit</div>
					</v-responsive>
				</div>

				<v-row class="mt-6">
					<v-col
						v-for="(item, i) in features2"
						:key="i"
						cols="12"
						md="6"
					>
						<v-card class="py-4 px-2">
							<div class="d-flex">
								<div class="mr-2">
									<v-sheet color="secondary" rounded class="pa-2" dark>
										<v-icon large>{{ item.icon }}</v-icon>
									</v-sheet>
								</div>
								<div>
									<div class="text-h5 font-weight-bold">{{ item.title }}</div>
									<div class="font-weight-regular mt-1">{{ item.description }}</div>
								</div>
							</div>

						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-sheet>



		<v-container class="pt-4 pt-md-8 pb-10">
			<v-responsive max-width="700" class="mx-auto text-center">
				<h2 class="text-h3 text-lg-h2">Pricing</h2>
				<div class="text-h4 primary--text mt-2">the most affordable solution on the market</div>
			</v-responsive>

			<v-row class="mt-10" dense>

				<v-col
					v-for="(plan, i) in plans"
					:key="i"
					cols="12"
					md="6"
					lg="3"
				>
					<v-card
						class="text-body-1"
						outlined
						elevation="2"
					>
						<v-sheet class="pa-4 text-center">
							<div class="mb-6">
								<div class="text-h6 font-weight-bold text-uppercase">{{ plan.title }}</div>
							</div>

							<div v-if="plan.price">
								<div class="d-flex justify-center ml-n3">
									<div class="font-weight-regular text-h4 mr-1" style="margin-top: 4px">$</div>
									<div class="text-h1">{{ plan.price }}</div>
								</div>

								<div class="grey--text justify-end overline">/ month</div>
							</div>

							<div v-else class="text-h6 my-2">
								free
							</div>

						</v-sheet>

						<div class="text-center">
							<v-chip v-if="plan.highlight" color="secondary" class="mb-n3 overline font-weight-bold" small>no CC necessary</v-chip>
						</div>

						<v-divider></v-divider>

						<v-sheet class="pa-4" color="surface">
							<div class="my-6 text-h6">
								<div v-for="(feat, j) in plan.features" :key="j" class="d-flex align-center my-1">
									<v-icon color="success" class="mr-2">mdi-check</v-icon>
									<span v-if="feat.value" class="font-weight-black mr-1">{{ feat.value }}</span>
									<span class="text-truncate">{{ feat.label }}</span>
								</div>
							</div>

							<v-btn
								x-large
								block
								outlined
								:dark="plan.highlight"
								@click="showSignup()"
								:class="[plan.highlight && 'secondary']"
							>
								{{ plan.price ? 'Contact us ↓' : 'Sign Up ↑' }} 
								
							</v-btn>
						</v-sheet>
					</v-card>
				</v-col>
			</v-row>
		</v-container>







		<v-sheet>
			<v-container class="py-8">
				<v-row>
					<v-col cols="12" md="6">
						<h3 class="text-h3">Contact us now!</h3>

						<div class="text-h6 text-lg-h5 my-3">You want to setup a test account? You want to Order? Questions? Feedback? Please write us!</div>
						
						<v-divider></v-divider>

						<div class="font-weight-bold text-body-1">
							<div class="d-flex align-center mt-3">
								<v-icon>mdi-map-marker-outline</v-icon>
								<span class="ml-1">HQ in Berlin, Germany</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-phone-outline</v-icon>
								<span class="ml-1">
									<a href="#" class="text-decoration-none">+49 (one) 57 74737712</a>
								</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-whatsapp</v-icon>
								<span class="ml-1">
									<a href="#" class="text-decoration-none">+49 (one) 57 74737712</a>
								</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-email-outline</v-icon>
								<span class="ml-1">
									<a href="mailto:hello@wunderoffice.com" class="text-decoration-none">hello (at) wunderoffice.com</a>
								</span>
							</div>
						</div>
					</v-col>

					<!--

					<v-col cols="12" md="6">
						<h3 class="text-h3">Sende uns eine Nachricht</h3>
						
						<v-form class="mt-2">
							<v-text-field label="Name"></v-text-field>
							<v-text-field label="Email"></v-text-field>
							<v-text-field label="Tel"></v-text-field>
							<v-textarea label="Nachricht"></v-textarea>
							<v-btn submit large color="secondary" min-width="180">senden</v-btn>
						</v-form>
					</v-col>
-->

				</v-row>
			</v-container>
		</v-sheet>




		<v-sheet>
			<v-container class="py-4 py-lg-8">
				<div class="text-center">
					<div class="text-uppercase font-weight-bold body-2 primary--text mb-2"></div> 

					<h2 class="text-h3 text-lg-h2">FAQs</h2>
					<v-responsive max-width="1200" class="mx-auto">
						<div class="text-h6 text-lg-h5 mt-4">Contact us for any more questions</div>
					</v-responsive>
				</div>

				<v-expansion-panels
					v-model="panel"
					multiple
					class="mt-8"
				>
					<v-expansion-panel
						v-for="(item, i) in faq"
						:key="i"
					>
						<v-expansion-panel-header class="text-h5">
							{{ item.question }}
						</v-expansion-panel-header>

						<v-expansion-panel-content class="text-h6 secondary--text">
							{{ item.answer }}
						</v-expansion-panel-content>

					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</v-sheet>

		<v-footer>
			<v-container class="py-5">
				<v-row>
					<v-col
						v-for="(item, i) in footer"
						:key="i"
						cols="12"
						sm="3"
						md="2"
					>
						<div class="text-h6 text-lg-h5 font-weight-bold">{{ item.title }}</div>
						<div style="width: 60px; height: 4px" class="mb-4 mt-1 secondary" />

						<div v-for="(link, j) in item.links" :key="j" class="my-1 body-1 font-weight-bold">
							<router-link
								v-if="link.to"
								class="text-decoration-none secondary--text"
								:to="link.to"
							>
								{{ link.label }}
							</router-link>

							<a
								v-else
								class="text-decoration-none secondary--text"
								:href="link.href"
								:target="link.target || 'blank'"
							>
								{{ link.label }}
							</a>
						</div>
					</v-col>
	<!--
					<v-col cols="12" md="4">
						<div class="text-h6 text-lg-h5 font-weight-bold">Newsletter</div>
						<div style="width: 60px; height: 4px" class="mb-4 mt-1 secondary" />
						<div class="text-body-1 mb-1">Erhalte unseren Newsletter</div>
						<div class="d-flex flex-column flex-lg-row w-full">

							<v-text-field
								outlined
								solo
								label="Deine Email"
								dense
								height="44"
								class="mr-lg-2"
							></v-text-field>
							<v-btn large color="secondary">Einschreiben</v-btn>

						</div>
					</v-col>

					-->
				</v-row>

				<v-divider class="my-4"></v-divider>

				<div class="d-flex flex-column flex-sm-row align-center justify-space-between mt-4 overline">
					<div class="d-flex flex-column flex-sm-row align-center">
						<div class="secondary--text">© WunderOffice 2023</div>
						<router-link to="/contact" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Legal Notice</router-link>
						<router-link to="/privacy" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Data Privacy</router-link>
						<router-link to="/terms" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Terms of Use</router-link>
					</div>

				

<!--
					<div class="mt-2 mt-md-0">
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-facebook</v-icon></v-btn>
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-github</v-icon></v-btn>
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-twitter</v-icon></v-btn>
--> 				
						<v-btn href="https://www.linkedin.com/company/wunderoffice">
            <v-icon>mdi-linkedin</v-icon></v-btn>

<!--
						<v-btn
    href="https://www.linkedin.com/company/wunderoffice"
    target="_blank"
    icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-linkedin
>
    <v-icon>window</v-icon> Link Text
</v-btn>
-->

<!--
						<v-btn icon href="https://www.linkedin.com/company/wunderoffice" target="_blank"> color="secondary lighten-2" class="ml-1"><v-icon>mdi-linkedin</v-icon></v-btn>
					
					<v-btn icon href="https://www.linkedin.com/company/wunderoffice" target="_blank">
  <v-icon>window</v-icon> Link Text
</v-btn>
			-->		
					</div>

					
				
			</v-container>
		</v-footer>

	</v-container>

</template>

<script>
import RegisterPopup from '@/components/RegisterPopup'
import LoginPopup from '@/components/LoginPopup'

export default {
	name: 'Index',
	components: {
		RegisterPopup,
		LoginPopup
	},
	metaInfo: {
		title: 'WunderOffice'
	},

	computed: {
		showReset() {
			return (window.location.hash.split('/')[1] == 'reset');
		}
	},

	methods: {
		showSignup() {
			this.$refs.signUpButton.showPopup();
		}
	},

	data() {
		return {

			menu: [{
				title: 'Produkt',
				link: '#product'
			}, {
				title: 'English',
				link: '#product'
			}, {
				title: '',
				link: ''
			}, {
				title: '',
				link: ''
			}],

			features: [{
				icon: 'mdi-seat',
				title: 'Reserve your seats ahead',
				description: 'Too many colleagues & not enough tables? With WunderOffice you can balance out the office occupancy'
			}, {
				icon: 'mdi-forum',
				title: 'Meet up in Office',
				description: 'See at a glance who is planning to go to the office & sort things out more efficiently face to face .... or just have a coffee together again'
			}, {
				icon: 'mdi-account-check',
				title: 'Keeps track of colleagues availability',
				description: 'Is the colleague still on vacation... on a business trip or at the customer? Mark the closest colleagues & stay up-to-date'
			}, {
				icon: 'mdi-calendar-multiple-check',
				title: 'Make meeting rooms & more bookable',
				description: 'Meeting rooms, (e-charge) parking spaces, co-working spaces, beamers, the basket for the office dog, etc. With WunderOffice you can book everything transparently'
			}],

			features2: [{
				icon: 'mdi-currency-eur', 
				title: 'Save office costs',
				description: 'Planning office presence allows reducing office space, heating, air conditioning, cleaning costs,... '
			}, {
				icon: 'mdi-pine-tree',
				title: 'Save CO2',
				description: 'On less busy days certain office areas may be less heated/ cooled. And of course there is less commuting with a hybrid office setup'
			}, {
				icon: 'mdi-food-fork-drink', 
				title: 'Plan office equipment & services efficiently',
				description: 'Forward office planning for desk equipment, cleaning services, catering & other things'
			}, {
				icon: 'mdi-chart-box-outline',
				title: 'Office Stats & Analytics',
				description: 'Statistics & analyses on office utilization enable the constant adjustment of office capacities & services'
			}, {
				icon: 'mdi-calendar-account',
				title: 'Create an overview of accessibility',
				description: 'With the favorites list, employees can quickly see at a glance which team members are reachable'
			}, {
				icon: 'mdi-forum',
				title: 'Facilitate face to face meetings in office',
				description: 'Some things are more efficiently resolved in persona & build important collegial relationships along the way'
			}, {
				icon: 'mdi-timelapse',
				title: 'Working time recording',
				description: 'Employees can record their daily working hours with one click in accordance with legal requirements (Beta)'
			}, {
				icon: 'mdi-calendar-multiselect-outline',
				title: 'Vacation coordination with the whole team',
				description: 'Facilitated coordination & vacation planning with all team members & affected colleagues'
			}, {	
				icon: 'mdi-palm-tree',
				title: 'Vacation days tracking',
				description: 'No need for other complicated tools & sheets. Get a quick overview of the remaining number of individual vacation days'
			}, {
				icon: 'mdi-lead-pencil',
				title: 'Tracking of Home-Office days',
				description: 'Employee overview of home office days per week/ month facilitates compliance with agreements/ regulations'
			}, {
				icon: 'mdi-map-clock-outline',
				title: 'Coordinate international teams more easily',
				description: 'View the current time of day, local working & public holidays & the presence status of international colleagues'
			}, {
				icon: 'mdi-api',
				title: 'API, Slack & MS Teams integrations',
				description: 'WunderOffice can be integrated into your existing tools on demand (Beta)'
			}, {
				icon: 'mdi-virus',
				title: 'Corona contact tracing',
				description: 'Protect employees better & reduce sick leaves with contact tracking in the office (plus less infections when commmuting)'
			}, {
				icon: 'mdi-account-group',
				title: 'Also applicable for individual teams',
				description: 'WunderOffice can also be used for only certain teams, departments, rooms, offices, co-working spaces... '
			}, {
				icon: 'mdi-tools',
				title: 'Individual customizations possible',
				description: 'Special adjustments regarding local legal or worker council requirements can be made by us'
			}, {
				icon: 'mdi-account-heart',
				title: 'Extremely simple to set up & use',
				description: 'WunderOffice is the easiest home & office planning web tool on the market. Forget Excel & Wikis or other complicated tools'
			}],

			plans: [{
				highlight: true,
				title: 'Test & Mini Office',
				price: '',
				features: [{
					value: '5',
					label: 'Employees'
					}, {
					value: 'Offices',
					label: '1'
					}, {
					value: 'German',
					label: 'Server'
					}, {
					value: 'Email',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Startup',
				price: '19',
				features: [{
					value: '10',
					label: 'Employees'
					}, {
					value: 'Offices',
					label: '1'
					}, {
					value: 'German',
					label: 'Server'
					}, {
					value: 'Email',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Small Company',
				price: '79',
				features: [{
					value: '50',
					label: 'Employees'
					}, {
					value: 'Offices',
					label: '2'
					}, {
					value: 'German',
					label: 'Server'
				}, {
					value: 'Email',
					label: 'Support'
					}, {
					value: 'Tel',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Big Company',
				price: '-',
				features: [{
					value: '100+',
					label: 'Employees'
				}, {
					value: 'Offices',
					label: '3+'
					}, {
					value: 'your',
					label: 'Server'
					}, {
					value: 'Tel',
					label: 'Support'
					}, {
					value: 'own',
					label: 'Subdomain'
					}, {
					value: 'API ',
					label: 'Integrationen'
					}, {
					value: 'Statistics',
					label: '& Analytics'
				}]
			}],

			faq: [{
				question: 'Can I test Wunderoffice with my team before I propose it to HR?',
				answer: 'Sure thing. Contact us and we will help you with the setup.'
			}, {	
				question: 'Can I also add external employees?',
				answer: 'Yes, external collaborators and freelancers can be added via email and can also schedule their attendance and seat time.'
			}, {
				question: 'Can employees also plan their stay in co-working spaces?',
				answer: 'Yes, each employee can independently create a co-working space and announce their stay there to their colleagues.'
			}, {	
				question: 'Can employees reserve seats in different offices?',
				answer: 'Yes, Wunderoffice allows you to manage all your offices and employees can announce when they will be in which office.'
			}, {	
				question: 'Can offices be added in foreign countries?',
				answer: 'Yes, please set the correct time zone for the foreign office, so that you will always see the current local time for each international office.'
			}, {
				question: 'How can I add employees or colleagues to Wunderoffice?',
				answer: 'der the point: "Manage Office" you can store the emails of your colleagues, so that they are invited to Wunderoffice by Email.'
			}],

			panel: [],

			footer: [{
				title: 'Product',
				links: [{
					label: 'V.1.3 (Beta)',
					to: '#'
				}]
			}, {
				title: 'Resources',
				links: [{
					label: 'FAQ',
					to: '#'
				}, {
					label: 'Press',
					href: 'https://sites.google.com/view/wunderoffice-press/home'
				}]
			}, {
				title: 'Company',
				links: [{
					label: 'Made in Berlin',
					to: '#'
				}, {
					label: 'Jobs',
					to: '#'
				}]
			}, {
				title: 'Support',
				links: [{
					label: 'Contact',
					to: '/contact'
				}]
			}]
		}
	}

}
</script>
