import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false;

Vue.use(Vuex);

//Global functions
Vue.mixin({
	methods: {
		$getIcon(value) {

			switch (value) {
				case -1: return "mdi-home";
				case -2: return "mdi-airplane";
				case -3: return "mdi-checkbox-blank-circle-outline";
				case -4: return "mdi-hospital-box";
				case -5: return "mdi-island";
				case 0: return "mdi-help-circle-outline";
				default: return "$chair-unreserved";
			}

		},

		$getIconActive(value) {

			switch (value) {
				case -1: return "mdi-home";
				case -2: return "mdi-airplane";
				case -3: return "mdi-checkbox-blank-circle-outline";
				case -4: return "mdi-hospital-box";
				case -5: return "mdi-island";
				case 0: return "mdi-help-circle-outline";
				default: return "$chair-occupied";
			}

		}

	}
});

//Global variables
const store = new Vuex.Store({
	state: {
		//Local cache of user session
		session: {},

		//Show a global notification
		notice: false,
		notice_msg: null

	},
	mutations: {		
		session(state, value) {

			state.session = {
				...state.session,
				...value
			};
		},

		clearSession(state) {
			state.session = {};
		},

		/*
		set(state, values) {
			for (const [key, value] of Object.entries(values)) {
				state[key] = value;
			}			
		}
		*/

		showNotice(state, value) {
			state.notice_msg = value;
			state.notice = true;
		}

	}
});

//This module allows changing HTML meta tags, such as title
Vue.use(VueMeta);

//Check access before showing pages
router.beforeEach(async (to, from, next) => {

	//Current page does not require a session
	if (to.meta.auth == 0) return next();
	
	//Session is set and auth level is ok
	if (store.state.session.level >= to.meta.auth) return next();

	//Show loader

	const response = await fetch(process.env.VUE_APP_API + "/account/session");

	//Hide loader

	//Not logged in, redirect to index
	if (!response.ok) return next("/");

	const data = await response.json();

	//Save session locally
	store.commit("session", data);

	//Auth level is too low, redirect to index
	if (data.level < to.meta.auth) return next("/");

	next();
});

new Vue({store, router, vuetify, render: h => h(App)}).$mount('#app');