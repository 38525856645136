<template>

    <span>
        <v-btn @click.stop="show=true" color="success">Add Colleagues</v-btn>

        <v-dialog max-width="500px" v-model="show">
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <v-toolbar dark color="primary">
                        <span>Add Colleagues</span>

                        <v-spacer />

                        <v-btn icon @click="show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="pt-6 pb-0">

                        <v-textarea v-model="mail"
                            label="E-mail address(es)"
                            no-grow
                            outlined
                            rows="5"
                            row-height="15"
                            messages="Enter one or more e-mail addresses, separated by comma (,) or new line"
                            :error-messages="errors.mail"
                            @focus="errors.mail=null"
                        />
                        
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="loading" class="mt-3">Add Colleagues</v-btn>
                    </v-card-actions>

                </v-card>
            </v-form>
        </v-dialog>

    </span>

</template>

<script>
export default {
    data() {
        return {
            show: false,
            mail: null,
            loading: false,
            errors: {
                mail: null
            }
        }
    },
    watch: {
        show(value) {

            //Clear form on dialog close
            if (!value) {
                this.errors = {
                    mail: null
                };
                this.$refs.form.reset();
            }
        }
    },
    methods: {
        async submit() {
            
            this.loading = true;

            //Loop through all e-mails and add to office or send invite emails and add to invite table!!

            const response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/invites", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    office: this.$route.params.id,
                    mail: this.mail
                })
            });

            const data = await response.json();

            this.loading = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.show = false;

            this.$emit("reloadTables");
        }
    }
}
</script>