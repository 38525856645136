<template>
    <v-btn v-google-signin-button="clientId" class="mb-5" block :loading="loading" @click="loading=true">
        <v-icon left>mdi-google</v-icon>
        Sign in with Google
    </v-btn>
</template>

<script>
import GoogleSignInButton from 'vue-google-signin-button-directive';

export default {
  directives: {
    GoogleSignInButton
  },

  data() {
    return {
        clientId: '361623746750-b6vta95p6uhvroutp86pd4g1cenu4cnv.apps.googleusercontent.com',
        loading: false
    } 
  },

  methods: {
    async OnGoogleAuthSuccess(token) {

        const response = await fetch(process.env.VUE_APP_API + "/account/google", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                token: token
            })
        });

        const data = await response.json();

        this.loading = false;

        if (!data.mail) return;
        
        //Save session to local cache
        this.$store.commit("session", data);

        this.$router.push("/dashboard");

    },
    OnGoogleAuthFail(error) {
        this.loading = false;
        console.log(error);
    }
  }
}
</script>