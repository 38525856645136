<template>

    <v-card max-width="1300" class="mx-auto pa-5 mt-5">

        <OverlayLoader :show="loading" />

        <v-card-title>
            <v-icon left>mdi-calendar-sync-outline</v-icon>
            Default work week
        </v-card-title>

        <v-card-subtitle>
            Your typical work week (These selecions will appear with a grey icon in your calendar & you have to click on the grey icon in order to confirm your plan so that your colleagues will see then a black icon)
        </v-card-subtitle>

        <v-form @submit.prevent="submit" ref="form">

            <v-select outlined dense v-for="day in days" :key="day.value"
                :items="options"
                v-model="week[day.value]"
                :label="day.text"
                :error-messages="errors[day.value]"
                @focus="errors[day.value]=null"
                @change="setIcon"
                :prepend-icon="icon[day.value]"
            >

                <template slot="item" slot-scope="data">
                    <v-icon left>{{ data.item.icon }}</v-icon>  {{ data.item.text }}
                </template>

            </v-select>

            <v-btn color="success" type="submit" :loading="loading2">
                <v-icon left dark>mdi-check</v-icon>
                Save Changes
            </v-btn>
        </v-form>
    </v-card>

</template>

<script>
import OverlayLoader from '@/components/OverlayLoader';

export default {
    components: {
        OverlayLoader
    },
    
    data() {
        return {
            loading: true,
            loading2: false,
            options: [
                { value: -1, text: "Working from home", icon: this.$getIcon(-1) },
                { value: -2, text: "Traveling (hard to reach)", icon: this.$getIcon(-2) },
                { value: -3, text: "Not working", icon: this.$getIcon(-3) },
            ],
            week: {
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
                saturday: null,
                sunday: null
            },
            icon: {
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
                saturday: null,
                sunday: null
            },
            days: [
                { value: "monday", text: "Monday" },
                { value: "tuesday", text: "Tuesday" },
                { value: "wednesday", text: "Wednesday" },
                { value: "thursday", text: "Thursday" },
                { value: "friday", text: "Friday" },
                { value: "saturday", text: "Saturday" },
                { value: "sunday", text: "Sunday" }
            ],
            errors: {
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
                saturday: null,
                sunday: null
            }
        }
    },

    async created() {

        //Load all offices this user belongs to
        let response = await fetch(process.env.VUE_APP_API + "/office/list");
        
        const data = await response.json();

        for (let company of data) {
            
            if (company.offices.length == 0) continue;

            for (let office of company.offices) {
                this.options.push(
                    { value: office.id, text: "Working at " + company.name + " (" + office.name + ")", icon: 'mdi-seat' }
                );
            }

        }

        //Load the default work week for the user
        response = await fetch(process.env.VUE_APP_API + "/account");

        let account = await response.json();

        this.week = (account.week) ? account.week : {};

        this.setIcon();

        this.loading = false;
    },

    methods: {
        setIcon() {
            for (let day in this.week) {
                if (this.week[day] > 0) this.icon[day] = "mdi-seat";
                else this.icon[day] = this.$getIcon(this.week[day]);
            }
        },
        
        async submit() {

            this.loading2 = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/work", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(this.week)
            });

            const data = await response.json();

            this.loading2 = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            //Update session to remove notice
            this.$store.commit("session", { week: true });

            this.$store.commit("showNotice", "Your changes has been saved");
        }
    }    
}

</script>
