<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" stroke="#000" stroke-linecap="round" stroke-linejoin="round" fill="#fff" fill-rule="evenodd">
        <path d="M2 15v3h3v-3h10v3h3v-6H2v3m15-8h3v3h-3V7M0 7h3v3H0V7m15 3H5V2c0-1.1046.8954-2 2-2h6c1.1046 0 2 .8954 2 2v8z" fill="red" fill-rule="nonzero" stroke="none"/>
        <path fill="none" d="M2 2l17 15"/>
    </svg>
</template>

<script>

export default {
    name: 'ChairFull',
}

</script>