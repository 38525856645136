<template>
    <span>
        <div v-if="item.value==0">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-help-circle-outline
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-help-circle-outline
                    </v-icon>
                </template>
                <span v-if="item.uid==$store.state.session.id">Please go to "My work profile" and set your typical work week</span>
                <span v-else>Unspecified</span>
            </v-tooltip>
        </div>

        <div v-if="item.value==-1">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-home
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-home
                    </v-icon>
                </template>
                <span v-if="item.default">Unconfirmed: Working from home</span>
                <span v-else>Confirmed: Working from home</span>
            </v-tooltip>
        </div>

        <div v-if="item.value==-2">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-airplane
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-airplane
                    </v-icon>
                </template>
                <span v-if="item.default">Unconfirmed: Traveling or Hard to reach</span>
                <span v-else>Confirmed: Traveling or Hard to reach</span>
            </v-tooltip>
        </div>

        <div v-if="item.value==-3">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-checkbox-blank-circle-outline
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-checkbox-blank-circle-outline
                    </v-icon>
                </template>
                <span v-if="item.default">Unconfirmed: Not working</span>
                <span v-else>Confirmed: Not working</span>
            </v-tooltip>
        </div>

        <div v-if="item.value==-4">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-hospital-box
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-hospital-box
                    </v-icon>
                </template>
                <span v-if="item.default">Unconfirmed: Sick leave</span>
                <span v-else>Confirmed: Sick leave</span>
            </v-tooltip>
        </div>

        <div v-if="item.value==-5">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon size="30" v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id" :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-island
                    </v-icon>
                    <v-icon size="30" v-bind="attrs" v-on="on" v-else :color="item.default ? 'grey lighten-1' : 'black'">
                        mdi-island
                    </v-icon>
                </template>
                <span v-if="item.default">Unconfirmed: Vacation leave</span>
                <span v-else>Confirmed: Vacation leave</span>
            </v-tooltip>
        </div>

        <div v-if="item.value > 0">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">

                   <v-icon v-bind="attrs" v-on="on" @click.stop="$emit('showPopup', item)" v-if="item.uid==$store.state.session.id">
                        {{ (item.default) ? "$chair-unreserved" : "$chair-occupied" }}
                    </v-icon>
 
                    <v-icon v-bind="attrs" v-on="on" v-else>
                        {{ (item.default) ? "$chair-unreserved" : "$chair-occupied" }}
                    </v-icon>
 
                    
                </template>
                <span v-if="item.seat">Seat {{ item.seat }} @ {{ item.office.office }}</span>
                <span v-else>Unreserved seat @ {{ item.office.office }}</span>
            </v-tooltip>

        </div>

    </span>

</template>

<script>

export default {
    props: [ "item" ]
}
</script>