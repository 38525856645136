import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

//Custom icons
import ChairOccupied from '@/icons/ChairOccupied';
import ChairUnreserved from '@/icons/ChairUnreserved';
import ChairUnavailable from '@/icons/ChairUnavailable';
import ChairFull from '@/icons/ChairFull';
import GermanFlag from '@/icons/GermanFlag';
import USAFlag from '@/icons/USAFlag';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            'chair-occupied': { component: ChairOccupied },
            'chair-unreserved': { component: ChairUnreserved },
            'chair-full': { component: ChairFull },
            'chair-unavailable': { component: ChairUnavailable },
            'flag-germany': { component: GermanFlag },
            'flag-usa': { component: USAFlag }
        }
    }    
});
