<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:v="http://vecta.io" width="20" height="18" viewBox="0 0 20 18"><g><g ><g class="kquutu2m3pb.lay" v:layer_name="unten"><path d="M 2 15 L 2 18 L 5 18 L 5 15 L 15 15 L 15 18 L 18 18 L 18 12 L 2 12 L 2 15 M 17 7 L 20 7 L 20 10 L 17 10 L 17 7 M 0 7 L 3 7 L 3 10 L 0 10 L 0 7 M 15 10 L 5 10 L 5 2 C 5 0.8954 5.8954 0 7 0 L 13 0 C 14.1046 0 15 0.8954 15 2 L 15 10 Z" fill="#000000" fill-rule="nonzero" stroke="none" stroke-linecap="butt" stroke-linejoin="miter"/></g><g transform="translate(3.3 6.5345)"><path d="M 13.5 6.8655 C 13.4495 5.838 13.1982 3.6427 12.9464 2.6622 C 12.694 1.5411 12.3917 0.9808 11.5358 0.5603 C 10.6794 0.1402 10.2262 0 9.9234 0 C 9.6715 0 9.5206 0.1402 9.3193 0.2801 C 9.2689 0.2801 9.2689 0.3268 9.2183 0.3268 C 8.4124 1.0273 7.6064 1.1675 6.75 1.1675 C 5.944 1.1675 5.1381 0.9808 4.2816 0.3268 C 4.2311 0.3268 4.2311 0.2801 4.1807 0.2801 C 4.0298 0.1402 3.8789 0 3.5766 0 C 3.2743 0 2.8206 0.1402 1.9647 0.5603 C 1.1082 0.9808 0.8059 1.5411 0.5541 2.6622 C 0.3522 3.6427 0.0505 5.7914 0 6.8655 Z" stroke="none" fill="#4CAF50"/></g><g transform="translate(6.0123 0.1188)"><path d="M 8.1745 3.5797 C 8.1745 1.6014 6.344 0 4.0869 0 C 1.8298 0 0 1.6014 0 3.5797 C 0 5.5576 1.8298 7.1591 4.0869 7.1591 C 6.344 7.1591 8.1745 5.6046 8.1745 3.5797 Z" stroke="none" fill="#4CAF50"/></g></g></g></svg>
</template>

<script>

export default {
    name: 'ChairOccupied',
}

</script>