<template>

	<v-container>

		<v-app-bar app height="80">
			<v-container class="py-0 px-0 px-sm-2 fill-height">
				<router-link to="#" class="d-flex align-center text-decoration-none mr-2">
					<img src="@/assets/images/wo-logo.png" alt="" height="48" class="mr-1">
					<span class="font-weight-black text-uppercase">
						WunderOffice
					</span>
				</router-link>

				<div class="d-none d-md-block">
<!--
					<v-btn v-for="(item, index) in menu" :key="index" text :to="item.link" class="mx-1">
						{{ item.title }}
					</v-btn>
-->
				</div>

				<v-spacer></v-spacer>

				<router-link to="/">
					<v-btn density="compact" icon>
						<v-icon>$flag-usa</v-icon>
					</v-btn>
				</router-link>

				<LoginPopup :reset="showReset" />
				<RegisterPopup ref="signUpButton" />

			</v-container>
		</v-app-bar>


		<!--
		<v-container class="py-6">
			<v-row>
				<v-col cols="12" md="6" lg="7">
					<h1 class="text-h4 text-sm-h3 text-lg-h1 mt-xl-4">Home + Office <span class="primary--text">transparent planen</span></h1>
					<h2 class="text-h6 text-sm-h5 secondary--text mt-4">Reserviere deinen Sitzplatz im Office & plane mit deinen Kollegen wann ihr erreichbar seid</h2>


					<div class="mt-4 mt-xl-10">

-->
<!--
						<v-responsive class="mt-8" max-width="600">

							<div class="d-flex flex-column flex-sm-row w-full">
								<v-text-field
									outlined
									solo
									label="deine Email"
									class="mr-2"
								/>


								<v-btn x-large color="secondary" min-width="180">Erhalte mehr Infos</v-btn>
							</div>

						</v-responsive>
-->

<!--


					</div>
				</v-col>
				
				<v-col cols="12" md="6" lg="5" class="d-none d-md-block">
					<v-img class="rounded-lg" :aspect-ratio="1" src="@/assets/images/hero.jpg" alt="" />
				</v-col>
			</v-row>
		</v-container>
	-->



<!--Home & Office auf einen Blick transparent planen, 
-->

	<v-container class="py-6 py-lg-12 text-center">
    <h1 class="text-h4 text-sm-h3 text-md-h2 text-lg-h1 mt-md-6">Home & Office <span class="primary--text">transparent planen</span></h1>
    <h2 class="text-h6 text-sm-h5 text--lighten-2 mt-4 ">Mit dem einfachsten & günstigsten Web Tool auf dem Markt</h2>
	<h2 class="text-h6 text-sm-h5 text--lighten-2 mt-4 "></h2>
    <v-img :src="require('@/assets/images/Landingpage_Screenshot_1_Ger.png')" alt="" class="rounded elevation-0 mb-6"> </v-img>


	
  </v-container>

		<v-sheet>

			<div class="text-center">
				<div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Die Vorteile für alle Office Worker</div>
				<h2 class="text-h3 text-lg-h2">Seid vorbereitet für den neuen Arbeitsmodus</h2>

				<v-responsive max-width="1200" class="mx-auto">
					<div class="text-h6 text-lg-h5 mt-4">Das beste aus beiden Welten optimal abstimmen: konzentriert von zu Hause + persönlich im Büro</div>
				</v-responsive>
			</div>

			<v-container class="py-4 py-lg-8 mt-5">
				<v-row>
					<v-col
						v-for="(item, i) in features"
						:key="i"
						cols="12"
						sm="6"
						lg="3"
					>

						<v-responsive max-width="300" class="mx-auto text-center">
							<div class="mb-2">
							<v-icon class="text-h1" color="primary">{{ item.icon }}</v-icon>
							</div>
							<div class="text-h5 mt-3">{{ item.title }}</div>
							<div class="font-weight-regular mt-2">{{ item.description }}</div>
						</v-responsive>

					</v-col>
				</v-row>
			</v-container>

		</v-sheet>

		<v-sheet color="transparent">
			<v-container class="py-4 py-lg-8">
				<div class="text-center">
					<div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Die Vorteile für HR & Office Management</div>
					<h2 class="text-h3 text-lg-h2">Macht euer Büro & Team fit für die Zukunft</h2>

					<v-responsive max-width="1200" class="mx-auto">
						<div class="text-h6 text-lg-h5 mt-4">Kosten sparen + Produktivität & Zufriedenheit erhöhen</div>
					</v-responsive>
				</div>

				<v-row class="mt-6">
					<v-col
						v-for="(item, i) in features2"
						:key="i"
						cols="12"
						md="6"
					>
						<v-card class="py-4 px-2">
							<div class="d-flex">
								<div class="mr-2">
									<v-sheet color="secondary" rounded class="pa-2" dark>
										<v-icon large>{{ item.icon }}</v-icon>
									</v-sheet>
								</div>
								<div>
									<div class="text-h5 font-weight-bold">{{ item.title }}</div>
									<div class="font-weight-regular mt-1">{{ item.description }}</div>
								</div>
							</div>

						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-sheet>



		<v-container class="pt-4 pt-md-8 pb-10">
			<v-responsive max-width="700" class="mx-auto text-center">
				<h2 class="text-h3 text-lg-h2">Pricing</h2>
				<div class="text-h4 primary--text mt-2">die günstigste Lösung auf dem Markt</div>
			</v-responsive>

			<v-row class="mt-10" dense>

				<v-col
					v-for="(plan, i) in plans"
					:key="i"
					cols="12"
					md="6"
					lg="3"
				>
					<v-card
						class="text-body-1"
						outlined
						elevation="2"
					>
						<v-sheet class="pa-4 text-center">
							<div class="mb-6">
								<div class="text-h6 font-weight-bold text-uppercase">{{ plan.title }}</div>
							</div>

							<div v-if="plan.price">
								<div class="d-flex justify-center ml-n3">
									<div class="font-weight-regular text-h4 mr-1" style="margin-top: 4px">$</div>
									<div class="text-h1">{{ plan.price }}</div>
								</div>

								<div class="grey--text justify-end overline">/ month</div>
							</div>

							<div v-else class="text-h6 my-2">
								free
							</div>

						</v-sheet>

						<div class="text-center">
							<v-chip v-if="plan.highlight" color="secondary" class="mb-n3 overline font-weight-bold" small>keine Kreditkarte nötig</v-chip>
						</div>

						<v-divider></v-divider>

						<v-sheet class="pa-4" color="surface">
							<div class="my-6 text-h6">
								<div v-for="(feat, j) in plan.features" :key="j" class="d-flex align-center my-1">
									<v-icon color="success" class="mr-2">mdi-check</v-icon>
									<span v-if="feat.value" class="font-weight-black mr-1">{{ feat.value }}</span>
									<span class="text-truncate">{{ feat.label }}</span>
								</div>
							</div>

							<v-btn
								x-large
								block
								outlined
								:dark="plan.highlight"
								@click="showSignup()"
								:class="[plan.highlight && 'secondary']"
							>
								{{ plan.price ? 'Kontaktiere uns ↓' : 'Sign Up ↑' }} 
								
							</v-btn>
						</v-sheet>
					</v-card>
				</v-col>
			</v-row>
		</v-container>







		<v-sheet>
			<v-container class="py-8">
				<v-row>
					<v-col cols="12" md="6">
						<h3 class="text-h3">Kontaktiere uns jetzt!</h3>

						<div class="text-h6 text-lg-h5 my-3">Testen? Bestellung? Fragen? Anregungen? Wir freuen uns auf deine Nachricht!</div>
						
						<v-divider></v-divider>

						<div class="font-weight-bold text-body-1">
							<div class="d-flex align-center mt-3">
								<v-icon>mdi-map-marker-outline</v-icon>
								<span class="ml-1">HQ in Berlin, Germany</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-phone-outline</v-icon>
								<span class="ml-1">
									<a href="#" class="text-decoration-none">+49 (eins) 57 74737712</a>
								</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-whatsapp</v-icon>
								<span class="ml-1">
									<a href="#" class="text-decoration-none">+49 (eins) 57 74737712</a>
								</span>
							</div>

							<div class="d-flex align-center mt-3">
								<v-icon>mdi-email-outline</v-icon>
								<span class="ml-1">
									<a href="mailto:hello@wunderoffice.com" class="text-decoration-none">hello (at) wunderoffice.com</a>
								</span>
							</div>
						</div>
					</v-col>

					<!--

					<v-col cols="12" md="6">
						<h3 class="text-h3">Sende uns eine Nachricht</h3>
						
						<v-form class="mt-2">
							<v-text-field label="Name"></v-text-field>
							<v-text-field label="Email"></v-text-field>
							<v-text-field label="Tel"></v-text-field>
							<v-textarea label="Nachricht"></v-textarea>
							<v-btn submit large color="secondary" min-width="180">senden</v-btn>
						</v-form>
					</v-col>
-->

				</v-row>
			</v-container>
		</v-sheet>




		<v-sheet>
			<v-container class="py-4 py-lg-8">
				<div class="text-center">
					<div class="text-uppercase font-weight-bold body-2 primary--text mb-2"></div> 

					<h2 class="text-h3 text-lg-h2">FAQs</h2>
					<v-responsive max-width="1200" class="mx-auto">
						<div class="text-h6 text-lg-h5 mt-4">die häufigsten Fragen kurz erläutert</div>
					</v-responsive>
				</div>

				<v-expansion-panels
					v-model="panel"
					multiple
					class="mt-8"
				>
					<v-expansion-panel
						v-for="(item, i) in faq"
						:key="i"
					>
						<v-expansion-panel-header class="text-h5">
							{{ item.question }}
						</v-expansion-panel-header>

						<v-expansion-panel-content class="text-h6 secondary--text">
							{{ item.answer }}
						</v-expansion-panel-content>
						
					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</v-sheet>

		<v-footer>
			<v-container class="py-5">
				<v-row>
					<v-col
						v-for="(item, i) in footer"
						:key="i"
						cols="12"
						sm="3"
						md="2"
					>
						<div class="text-h6 text-lg-h5 font-weight-bold">{{ item.title }}</div>
						<div style="width: 60px; height: 4px" class="mb-4 mt-1 secondary" />

						<div v-for="(link, j) in item.links" :key="j" class="my-1 body-1 font-weight-bold">
							<router-link
								v-if="link.to"
								class="text-decoration-none secondary--text"
								:to="link.to"
							>
								{{ link.label }}
							</router-link>

							<a
								v-else
								class="text-decoration-none secondary--text"
								:href="link.href"
								:target="link.target || 'blank'"
							>
								{{ link.label }}
							</a>
						</div>
					</v-col>
	<!--
					<v-col cols="12" md="4">
						<div class="text-h6 text-lg-h5 font-weight-bold">Newsletter</div>
						<div style="width: 60px; height: 4px" class="mb-4 mt-1 secondary" />
						<div class="text-body-1 mb-1">Erhalte unseren Newsletter</div>
						<div class="d-flex flex-column flex-lg-row w-full">

							<v-text-field
								outlined
								solo
								label="Deine Email"
								dense
								height="44"
								class="mr-lg-2"
							></v-text-field>
							<v-btn large color="secondary">Einschreiben</v-btn>

						</div>
					</v-col>

					-->
				</v-row>

				<v-divider class="my-4"></v-divider>

				<div class="d-flex flex-column flex-sm-row align-center justify-space-between mt-4 overline">
					<div class="d-flex flex-column flex-sm-row align-center">
						<div class="secondary--text">© WunderOffice 2023</div>
						<router-link to="/contact" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Impressum</router-link>
						<router-link to="/privacy" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">Datenschutzerklärung</router-link>
						<router-link to="/terms" class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">AGB</router-link>
					</div>

				

<!--
					<div class="mt-2 mt-md-0">
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-facebook</v-icon></v-btn>
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-github</v-icon></v-btn>
						<v-btn icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-twitter</v-icon></v-btn>
--> 				
						<v-btn href="https://www.linkedin.com/company/wunderoffice">
            <v-icon>mdi-linkedin</v-icon></v-btn>

<!--
						<v-btn
    href="https://www.linkedin.com/company/wunderoffice"
    target="_blank"
    icon color="secondary lighten-2" class="ml-1"><v-icon>mdi-linkedin
>
    <v-icon>window</v-icon> Link Text
</v-btn>
-->

<!--
						<v-btn icon href="https://www.linkedin.com/company/wunderoffice" target="_blank"> color="secondary lighten-2" class="ml-1"><v-icon>mdi-linkedin</v-icon></v-btn>
					
					<v-btn icon href="https://www.linkedin.com/company/wunderoffice" target="_blank">
  <v-icon>window</v-icon> Link Text
</v-btn>
			-->		
					</div>

					
				
			</v-container>
		</v-footer>

	</v-container>

</template>

<script>
import RegisterPopup from '@/components/RegisterPopup'
import LoginPopup from '@/components/LoginPopup'

export default {
	name: 'Index',
	components: {
		RegisterPopup,
		LoginPopup
	},
	metaInfo: {
		title: 'WunderOffice'
	},

	computed: {
		showReset() {
			return (window.location.hash.split('/')[1] == 'reset');
		}
	},

	methods: {
		showSignup() {
			this.$refs.signUpButton.showPopup();
		}
	},

	data() {
		return {

			menu: [{
				title: 'Produkt',
				link: '#product'
			}, {
				title: 'English',
				link: '#product'
			}, {
				title: '',
				link: ''
			}, {
				title: '',
				link: ''
			}],

			features: [{
				icon: 'mdi-seat',
				title: 'Reserviert euren Tisch vorab',
				description: 'Zu viele Kollegen & zu wenige Tische? Mit Wunderoffice könnt ihr euch abstimmen & das Büro ist immer optimal ausgelastet'
			}, {
				icon: 'mdi-forum',
				title: 'Trefft euch gezielt im Office',
				description: 'Seht auf einen Blick wer wann plant ins Office zu gehen & stimmt euch ab um Dinge effizienter face-2-face zu klären... oder einfach mal wieder gemeinsam einen Cafe zu trinken'
			}, {
				icon: 'mdi-account-check',
				title: 'Behaltet den Überblick über die Erreichbarkeit von Kollegen',
				description: 'Ist der Kollege noch im Urlaub... auf Geschäftreise oder doch beim Kunden? Markiert die engsten Kollegen & bleibt up-to-date'
			}, {
				icon: 'mdi-calendar-multiple-check',
				title: 'Meeting Räume & mehr buchbar machen',
				description: 'Meeting-Räume, (E-Lade) Parkplätze, Co-Working Spaces, Beamer, das Körbchen für den Office-Hund etc. Mit WunderOffice kann man alles transparent buchbar machen'
			}],

			features2: [{
				icon: 'mdi-currency-eur', 
				title: 'Bürokosten sparen',
				description: 'Die Planung von Büroanwesenheiten ermöglicht die Reduzierung von Bürofläche, Heiz-, Klimaanlagen-, Reinigungs- Kosten... '
			}, {
				icon: 'mdi-pine-tree',
				title: 'CO2 sparen',
				description: 'An weniger frequentierten Tagen können bestimmte Bürobereiche weniger beheizt/gekühlt werden'
			}, {
				icon: 'mdi-food-fork-drink', 
				title: 'Büroausstattung & Services effizient planen',
				description: 'Vorausschauende Office Planung für Schreibtischausstattung, Reinigung, Verpflegung etc.'
			}, {
				icon: 'mdi-chart-box-outline',
				title: 'Office Analysen',
				description: 'Statistiken & Analysen zu Office Auslastung ermöglichen die stetige Anpassung von Office Kapazitäten'
			}, {
				icon: 'mdi-calendar-account',
				title: 'Überblick über die Erreichbarkeit schaffen',
				description: 'Mit der Favoriten-Liste sehen Mitarbeiter schnell auf einen Blick welche Team Mitglieder erreichbar sind'
			}, {
				icon: 'mdi-forum',
				title: 'Persönliche Treffen im Büro vereinfachen',
				description: 'Manche Dinge klärt man effizienter in Persona & baut nebenbei wichtige kollegiale Beziehungen auf'
			}, {
				icon: 'mdi-timelapse',
				title: 'Arbeitszeiterfassung',
				description: 'Mitarbeiter können gemäß gesetzlichen Anforderungen mit einem Klick Ihre täglichen Arbeitszeiten erfassen (Beta)'
			}, {
				icon: 'mdi-calendar-multiselect-outline',
				title: 'Urlaubs-Abstimmung mit dem Team',
				description: 'Erleichterte Abstimmung & Urlaubs-Planung mit allen Team- Kollegen'
			}, {	
				icon: 'mdi-palm-tree',
				title: 'Tracking von Urlaubstagen',
				description: 'Übersicht über die verbleibende Anzahl von individuellen Urlaubstagen'
			}, {
				icon: 'mdi-lead-pencil',
				title: 'Tracking von Home-Office Tagen',
				description: 'Die Mitarbeiter-Übersicht von Home-Office Tagen pro Woche/Monat erleichtert die Einhaltung von Vereinbarungen/ Regelungen'
			}, {
				icon: 'mdi-map-clock-outline',
				title: 'Internationale Teams leichter koordinieren',
				description: 'Die aktuelle Tageszeit, lokale Arbeits- & Feiertage & den Anwesenheitsstatus von internationalen Kollegen einsehen'
			}, {
				icon: 'mdi-api',
				title: 'API, Slack & MS Teams Integrationen',
				description: 'WunderOffice kann auf Wunsch in eure bestehenden Tools integriert werden (Beta)'
			}, {
				icon: 'mdi-virus',
				title: 'Corona Kontakt-Rückverfolgung',
				description: 'Mitarbeiter besser schützen durch die nahtlose Rückverfolgung von Kontakten im Büro'
			}, {
				icon: 'mdi-account-group',
				title: 'Auch anwendbar für einzelne Teams',
				description: 'WunderOffice lässt sich auch für nur bestimmte Teams, Räume, Offices, Co-Working Spaces nutzen'
			}, {
				icon: 'mdi-tools',
				title: 'Individuelle Anpassungen möglich',
				description: 'Sonderwünsche bzgl. lokaler DSVGO oder Betriebsratvorgaben können von uns vorgenommen werden'
			}, {
				icon: 'mdi-account-heart',
				title: 'Extrem einfach aufzusetzen & zu benutzen',
				description: 'WunderOffice ist das einfachste Webtool zur Home & Office Planung auf dem Markt. Vergesst Excel & Wikis'
			}],

			plans: [{
				highlight: true,
				title: 'Test & Mini Office',
				price: '',
				features: [{
					value: '5',
					label: 'Mitarbeiter'
					}, {
					value: 'Offices',
					label: '1'
					}, {
					value: 'Deutsche',
					label: 'Server'
					}, {
					value: 'Email',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Startup',
				price: '19',
				features: [{
					value: '10',
					label: 'Mitarbeiter'
					}, {
					value: 'Offices',
					label: '1'
					}, {
					value: 'Deutsche',
					label: 'Server'
					}, {
					value: 'Email',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Small Company',
				price: '79',
				features: [{
					value: '50',
					label: 'Mitarbeiter'
					}, {
					value: 'Offices',
					label: '2'
					}, {
					value: 'Deutsche',
					label: 'Server'
				}, {
					value: 'Email',
					label: 'Support'
					}, {
					value: 'Telefon',
					label: 'Support'
				}]
			}, {
				highlight: false,
				title: 'Big Company',
				price: '-',
				features: [{
					value: '100+',
					label: 'Mitarbeiter'
				}, {
					value: 'Offices',
					label: '3+'
					}, {
					value: 'eure',
					label: 'Server'
					}, {
					value: 'Telefon',
					label: 'Support'
					}, {
					value: 'eigene',
					label: 'Subdomain'
					}, {
					value: 'API ',
					label: 'Integrationen'
					}, {
					value: 'Statistiken ',
					label: '& Analysen'
				}]
			}],

			faq: [{
				question: 'Kann ich Wunderoffice mit meinen Team testen bevor ich es HR vorschlage?',
				answer: 'Na klar. Kontaktiere uns und wir helfen dir mit der Einrichtung'
			}, {	
				question: 'Kann ich auch externe Mitarbeiter hinzufügen?',
				answer: 'Ja, externe Mitarbeiter und Freelancer können per Email hinzugefügt werden und können ebenfalls ihre Anwesenheit und Sitzplatz planen'
			}, {
				question: 'Können Mitarbeiter auch ihren Aufenthalt in Co-Working Spaces planen?',
				answer: 'Ja. Jeder Mitarbeiter kann eigenständig ein Co-Working Space anlegen und den Aufenthalt dort an seine Kollegen ankündigen'
			}, {	
				question: 'Können Mitarbeiter in verschiedenen Offices sitzen?',
				answer: 'Ja, Wunderoffice erlaubt das Verwalten von allen euren Offices und Mitarbeiter können ankündigen wann sie in welchem Office sitzen werden'
			}, {	
				question: 'Können Offices im Ausland hinzugefügt werden?',
				answer: 'Ja. Bitte setzte die richtige Zeitzone für das ausländische Office, so dass man immer die aktuelle lokale Zeit angezeigt bekommt für jedes internationale Office'
			}, {
				question: 'Wie kann ich Mitarbeiter bzw. Kollegen zu Wunderoffice hinzufügen?',
				answer: 'Unter dem Punkt: "Manage Office" kannst du die Emails deiner Kollegen hinterlegen, damit Sie zu Wunderoffice per Email eingeladen werden'
			}],

			panel: [],

			footer: [{
				title: 'Produkt',
				links: [{
					label: 'V.1.3 (Beta)',
					to: '#'
				}]
			}, {
				title: 'Resourcen',
				links: [{
					label: 'FAQ',
					to: '#'
				}, {
					label: 'Presse',
					href: 'https://sites.google.com/view/wunderoffice-press/home'
				}]
			}, {
				title: 'Company',
				links: [{
					label: 'Made in Berlin',
					to: '/Index_English'
				}, {
					label: 'Jobs',
					to: '#'
				}]
			}, {
				title: 'Support',
				links: [{
					label: 'Kontakt',
					to: '/contact'
				}]
			}]
		}
	}

}
</script>