<template>
    
    <v-card min-height="400" max-width="1300" class="mx-auto" elevation="0">

        <OverlayLoader :show="loading" />

        <v-dialog v-model="dialog" persistent max-width="500" :retain-focus="false">
            <v-card>
                <v-toolbar dark color="primary">
                    {{ selection.name }}
                </v-toolbar>

                <v-card-text class="pt-5">This will also remove all offices that belongs to this company! Are you sure?</v-card-text>

                <v-card-actions>
                
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="dialog=false">Cancel</v-btn>
                    <v-btn text dark class="error" :loading="loading2" @click="removeCompany(selection)">Remove Company</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" persistent max-width="500" :retain-focus="false">
            <v-card>
                <v-toolbar dark color="primary">
                    {{ selection.name }}
                </v-toolbar>
                <v-card-text class="pt-5">Are you sure you want to remove this office?</v-card-text>
                <v-card-actions>
                
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="dialog2=false">Cancel</v-btn>
                    <v-btn text dark class="error" :loading="loading3" @click="removeOffice(selection)">Remove Office</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card max-width="1300" class="mx-auto mb-8" v-for="company in companies" :key="company.id">

            <v-toolbar flat class="pt-5" style="height: 65px">

                <v-toolbar-title>
                    <v-icon left>mdi-factory</v-icon>
                    {{ company.name }}
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <div v-if="company.editable" style="text-align: right;">
                    <v-btn color="error" dark @click.stop="showConfirm(company)" class="mr-3">
                        <v-icon left>mdi-minus</v-icon>
                        Remove Company
                    </v-btn>

                    <EditCompanyPopup :company="company" @updateCompany="updateCompany" />

                    <div class="mt-3">
                        <OfficeCreatePopup :company="company.id" @reloadOffices="reloadOffices" class="mt-5" />
                    </div>
                </div>

            </v-toolbar>

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left"></th>
                            <th class="text-right" v-if="company.editable"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(office) in company.offices" :key="office.id">

                            <td>

                                <v-list-item two-line class="pl-0">
                                    <v-list-item-icon class="mb-0 mr-4">
                                        <v-icon>mdi-office-building-outline</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ office.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ office.location }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                
                            </td>
                            <td class="text-right" v-if="company.editable">
                                <v-btn color="error" dark @click.stop="showConfirm2(office)" class="mr-3">
                                    <v-icon left>mdi-domain-remove</v-icon>
                                    Remove Office
                                </v-btn>

                                <router-link :to="'/office/' + office.id + '/edit'" custom v-slot="{href, navigate}">
                                    <v-btn :href="href" @click="navigate" class='success'>
                                        <v-icon left>mdi-pencil-outline</v-icon>
                                        Edit Office
                                    </v-btn>
                                </router-link>                                
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>


    </v-card>

</template>

<script>
import OfficeCreatePopup from '@/components/OfficeCreatePopup';
import OverlayLoader from '@/components/OverlayLoader';
import EditCompanyPopup from '@/components/EditCompanyPopup';

export default {
    components: {
        OfficeCreatePopup,
        OverlayLoader,
        EditCompanyPopup
    },

    data() {
        return {
            dialog: false,
            dialog2: false,
            loading: true,
            loading2: false,
            loading3: false,
            loading4: false,
            companies: [],
            selection: {},
        }
    },

    props: [ "reload" ],

    created() {
        this.reloadOffices();
    },

    watch: {
        reload() {
            this.reloadOffices();
        }
    },

    methods: {
        
        showConfirm(item) {
            this.selection = item;
            this.dialog = true;
        },

        showConfirm2(item) {
            this.selection = item;
            this.dialog2 = true;
        },

        async removeCompany(company) {
            this.loading2 = true;

            await fetch(process.env.VUE_APP_API + "/company/" + company.id, {
                method: "DELETE"
            });

            //Remove company and sub-offices from the list
            for (let item in this.companies) {
                if (company.id == this.companies[item].id) {
                    this.companies.splice(item, 1);
                    break;
                }
            }

            this.loading2 = false;
            this.dialog = false;

        },

        async removeOffice(office) {
            this.loading3 = true;

            await fetch(process.env.VUE_APP_API + "/office/" + office.id, {
                method: "DELETE"
            });

            //Remove office from the list
            dance:
            for (let index in this.companies) {
                for (let index2 in this.companies[index].offices) {
                    if (this.companies[index].offices[index2].id == office.id) {
                        this.companies[index].offices.splice(index2, 1);
                        break dance;
                    }
                }
            }

            this.loading3 = false;
            this.dialog2 = false;
        },

        updateCompany(id, name) {

            for (let item in this.companies) {
                if (id == this.companies[item].id) {
                    this.companies[item].name = name;
                    break;
                }
            }

        },

        async reloadOffices() {

            this.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/company/list");

            this.companies = await response.json();

            this.loading = false;
        }
    }

}
</script>
