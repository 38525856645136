<template>
    <div>
        <v-app-bar class="purple-lighten-5" app>

            <v-toolbar-title class="text-uppercase grey--text">
                <router-link to="/dashboard" class="d-flex align-center text-decoration-none mr-2">
					<img src="@/assets/images/wo-logo.png" alt="" height="48" class="mr-1" />
					<span class="font-weight-black text-uppercase">
						WunderOffice
					</span>
                </router-link>
            </v-toolbar-title>

            <v-spacer />

            <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x v-if="this.$store.state.session.id">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn max-height="37" max-width="37" fab outlined icon class="mx-2" v-bind="attrs" v-on="on">

                        <v-avatar size=35>
                            <v-icon size=35>mdi-menu</v-icon>
                        </v-avatar>

                    </v-btn>                    
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-btn max-height="37" max-width="37" fab outlined icon disabled class="mr-5">
                                <v-avatar size=35>
                                    <v-icon size=35>mdi-menu</v-icon>
                                </v-avatar>
                            </v-btn>

                            <v-list-item-content>
                                <v-list-item-title>{{ this.$store.state.session.mail }}</v-list-item-title>
                                <v-list-item-subtitle>{{ this.$store.state.session.title }}</v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>
                    </v-list>

                    <v-divider />

                    <v-list>

                        <v-list-item to="/dashboard">
                            <v-list-item-icon>
                                <v-icon>mdi-calendar-month</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Dashboard</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/profile">
                            <v-list-item-icon>
                                <v-icon>mdi-account-circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>My work profile</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/company/offices">
                            <v-list-item-icon>
                                <v-icon>mdi-office-building-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Office settings</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                    <v-divider />

                    <v-list>

                        <v-list-item to="/contact">
                            <v-list-item-icon>
                                <v-icon>mdi-chat-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Kontakt / Contact</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/terms">
                            <v-list-item-icon>
                                <v-icon>mdi-format-section</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>AGB / Terms of Use</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/privacy">
                            <v-list-item-icon>
                                <v-icon>mdi-security</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Datenschuterklärung / Privacy Policy</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                    <v-divider />

                    <v-list>
                        <v-list-item link @click="logout">
                            <v-list-item-icon>
                                <v-icon v-if="!logoutLoader">mdi-logout-variant</v-icon>
                                <v-progress-circular indeterminate small color="black" size="20" v-if="logoutLoader" class="mt-1" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Log out</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                </v-card>
            </v-menu>

        </v-app-bar>

    </div>

</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            domain: process.env.VUE_APP_URL,
            menu: false,
            menu2: false,
            logoutLoader: false
        }
    },
    methods: {
        async logout() {
            this.logoutLoader = true;

            //Do API call to log out
            await fetch(process.env.VUE_APP_API + "/account/logout");

            this.logoutLoader = false;

            //Remove local session cache
            this.$store.commit('clearSession');

            this.$router.push("/");
        }
    }
}
</script>