<template>
    
    <div>

        <v-data-table disable-pagination 
            :headers="headers"
            :items="users"
            :loading="loading"
            :hide-default-footer="true"
            :sort-desc="[true, false]"
            :sort-by="['starred', 'fullname']"
        >

            <template v-slot:[`item.date_${index-1}`]="{ item }" v-for="index in 14">
                <ScheduleIcon :item="item[`date_${index-1}`]" :key="index" />
            </template>

            <template v-slot:[`item.picture`]="{ item }">
                    <v-avatar size="35">
                        <v-icon size="35" v-if="!item.picture">mdi-account-circle</v-icon>
                        <img :src="domain + '/pictures/' + item.picture + '.35x35.jpg'" v-if="item.picture" />
                    </v-avatar>
            </template>

            <template v-slot:[`item.starred`]="{ item }">
                <span v-if="item.uid && item.uid != $store.state.session.id">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn fab small text @click.stop="toggleStar(item)" :loading="loading2[item.uid]">
                                <v-icon v-bind="attrs" v-on="on" v-if="!item.starred" class="grey--text">mdi-star-outline</v-icon>
                                <v-icon v-bind="attrs" v-on="on" v-if="item.starred" class="yellow--text">mdi-star</v-icon>
                            </v-btn>

                        </template>
                        <span v-if="item.starred">Remove from favorites</span>
                        <span v-if="!item.starred">Add to favorites</span>
                    </v-tooltip>

                </span>
            </template>

            <template slot="no-data">No colleagues found</template>

            <template v-slot:[`header.picture`]="{}">
                <v-icon size="35">mdi-account-circle</v-icon>
            </template>

            <template v-slot:[`header.fullname`]="{}">
                <v-form @submit.prevent="searchUsers" ref="form">

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search colleagues"
                        single-line
                        hide-details
                        outlined
                        dense
                    />

                </v-form>
            </template>

            <template v-slot:[`item.fullname`]="{ item }">
                <v-tooltip bottom v-if="item.title">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.fullname }}</span>
                    </template>
                    <span>{{ item.title }}</span>
                </v-tooltip>
                <span v-if="!item.title">{{ item.fullname }}</span>
            </template>

        </v-data-table>
    </div>

</template>

<script>
import ScheduleIcon from '@/components/ScheduleIcon'

export default {
    components: {
        ScheduleIcon
    },
    
    props: [ "currentDate" ],

    data() {
        return {
            domain: process.env.VUE_APP_URL,
            headers: [],
            users: [],
            loading: true,
            search: "",
            loading2: {}
        }
    },

    created() {
        this.loadSchedule();
    },

    methods: {

        async toggleStar(item) {

            this.$set(this.loading2, item.uid, true);

            await fetch(process.env.VUE_APP_API + "/star", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    user: item.uid
                })
            });

            this.$set(this.loading2, item.uid, false);

            item.starred = !item.starred;

        },

        searchUsers() {
            this.loadSchedule();
        },

        dateFormat(date) {
            return date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate();
        },

        //Load user schedules and office availability
        async loadSchedule() {

            this.loading = true;

            //Create table headers
            let headers = [{
                text: "",
                value: "starred",
                filterable: false, 
                sortable: false,
                align: "center",
                width: "70"
            },
            {
                text: "",
                value: "picture",
                filterable: false, 
                sortable: false,
                width: "60"
            },
            {
                text: "",
                align: "start",
                value: "fullname",
                filterable: false, 
                sortable: false
            }];

            //let weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

            //let date = new Date();
            //date.setDate(this.currentDate.getDate());

            for (let i=0; i<=13; i++) {

                //let fullDate = date.getDate() + "." + (date.getMonth()+1) + " " + weekDays[date.getDay()];
                
                headers.push({ text: "", width: "58", value: "date_" + i, filterable: false, sortable: false, align: "center" });

                //date.setDate(date.getDate()+1);
            }

            //Load all coworker schedules
            let response = await fetch(process.env.VUE_APP_API + "/schedule/coworkers", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    date: this.dateFormat(this.currentDate),
                    search: this.search
                })
            });

            let data = await response.json();

            this.headers = headers;
            this.users = [];

            for (let user of data) {
                
                let schedule = [];
                let index = 0;
                for (let day of user.schedule) {

                    schedule["date_" + index] = {
                        index: index,
                        uid: user.id,
                        ... day
                    }

                    index++;
                }
                
                let row = {
                    fullname: (user.firstname || user.lastname) ? user.firstname + " " + user.lastname : user.mail,
                    uid: user.id,
                    picture: user.picture,
                    starred: user.starred,
                    title: user.title,
                    ... schedule
                }

                this.users.push(row);
            }

            this.loading = false;

        }

    }

}
</script>

<style scoped>
::v-deep td, ::v-deep th {
    padding: 0 !important;
}

::v-deep th {
    border-top: thin solid #000 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

</style>