<template>
    <v-btn v-facebook-login-button="appId" class="mb-5" block :loading="loading" @click="loading=true">
        <v-icon left>mdi-facebook</v-icon>
        Sign in with Facebook
    </v-btn>
</template>

<script>
import FacebookLoginButton from 'vue-facebook-login-button-directive'

export default {
  directives: {
    FacebookLoginButton
  },

  data() {
        return {
            appId: '848995692419318',
            loading: false
        }
  },

  methods: {
    async OnFacebookAuthSuccess(token) {

        const response = await fetch(process.env.VUE_APP_API + "/account/facebook", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                token: token.accessToken
            })
        });

        const data = await response.json();

        this.loading = false;

        if (!data.mail) return;
        
        //Save session to local cache
        this.$store.commit("session", data);

        this.$router.push("/dashboard");

    },

    OnFacebookAuthFail (error) {
        this.loading = false;
        console.log(error);
    }
  }
}
</script>

<style scoped>
.fb-login-button {
  height: 50px;
}
</style>