<template>

    <span>
        <v-btn @click.stop="dialogMail.show=true" ref="signup" color="primary" large>Sign up</v-btn>

        <v-dialog max-width="500px" v-model="dialogMail.show">
            <v-form @submit.prevent="submitMail" ref="formMail">

                <v-card>
                    <v-toolbar dark color="primary">
                        <span>Sign up</span>

                        <v-spacer />

                        <v-btn icon @click="dialogMail.show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="pb-0 mt-3">
                            <v-text-field outlined dense label="E-mail address" v-model="dialogMail.mail" prepend-icon="mdi-mail" :error-messages="dialogMail.errors.mail" @focus="dialogMail.errors.mail=null" />
                            
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <h5 class="grey--text mx-8">By clicking Sign Up, you agree to our <router-link to="/terms">Terms</router-link> and confirm that you have read our <router-link to="/privacy">Data Use Policy</router-link>.</h5>
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="dialogMail.loading">Sign up</v-btn>
                    </v-card-actions>

                </v-card>
            </v-form>

        </v-dialog>

        <v-dialog max-width="500px" v-model="dialogPassword.show">
            <v-form @submit.prevent="submitPassword" ref="formPassword">

                <v-card>
                    <v-toolbar dark color="primary">
                        <span>Register account</span>

                        <v-spacer />

                        <v-btn icon @click="dialogPassword.show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="pb-0 mt-3">

                        <v-text-field outlined dense label="E-mail code" v-model="dialogPassword.code" prepend-icon="mdi-mail" 
                            v-if="dialogPassword.showCode"
                            :error-messages="dialogPassword.errors.code" 
                            @focus="dialogPassword.errors.code=null"
                            messages="The 5 digit code can be found in your e-mail inbox" />

                        <v-text-field outlined dense v-model="dialogPassword.password" prepend-icon="mdi-key" 
                            :append-icon="dialogPassword.showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                            :type="dialogPassword.showPassword ? 'text' : 'password'" 
                            label="Password" messages="Pick a password. At least 8 characters"
                            :error-messages="dialogPassword.errors.password"
                            @focus="dialogPassword.errors.password=null"
                            @click:append="dialogPassword.showPassword = !dialogPassword.showPassword" />
                            
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="dialogPassword.loading">Register</v-btn>
                    </v-card-actions>

                </v-card>

            </v-form>
             
        </v-dialog>

    </span>

</template>

<script>
export default {
    data() {
        return {
            dialogMail: {
                show: false,
                loading: false,
                mail: null,
                errors: {
                    mail: null
                }
            },
            dialogPassword: {
                show: false,
                loading: false,
                showPassword: false,
                mail: null,
                code: null,
                password: null,
                showCode: true,
                errors: {
                    mail: null,
                    code: null,
                    password: null
                }
            }
        }
    },
    watch: {
        "dialogMail.show"(value) {

            //Clear form on dialog close
            if (!value) {
                this.dialogMail.errors = {
                    mail: null
                };
                this.$refs.formMail.reset();
            }
        },
        "dialogPassword.show"(value) {

            //Clear form on dialog close
            if (!value) {
                this.dialogPassword.errors = {
                    mail: null,
                    code: null,
                    password: null
                };
                this.$refs.formPassword.reset();
            }
        }
    },
    mounted() {
        let parts = window.location.hash.split("/");
        if (parts[1] != "register") return;

        this.dialogPassword.mail = parts[2];
        this.dialogPassword.code = parts[3];

        this.dialogPassword.showCode = false;
        this.dialogPassword.show = true;
    },
    methods: {
        showPopup() {
            this.dialogMail.show=true;
        },
        
        async submitMail() {

            this.dialogMail.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/register", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    url: "https://wunderoffice.com/#/register/{mail}/{code}",
                    mail: this.dialogMail.mail
                })
            });

            const data = await response.json();

            this.dialogMail.loading = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.dialogMail.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.dialogPassword.mail = this.dialogMail.mail;

            this.dialogMail.show = false;
            this.dialogPassword.show = true;


        },
        async submitPassword() {

            this.dialogPassword.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    password: this.dialogPassword.password,
                    code: this.dialogPassword.code,
                    mail: this.dialogPassword.mail
                })
            });

            const data = await response.json();

            this.dialogPassword.loading = false;

            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.dialogPassword.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.dialogPassword.show = false;

            //Save session to local cache
            this.$store.commit("session", data);

            //Account created
            this.$router.push("/dashboard");

        }

    }
}
</script>
