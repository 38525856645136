<template>

    <v-card max-width="1300" class="mx-auto pa-5 mt-5">

        <v-card-title>
            <v-icon left>mdi-seat</v-icon>
            Preferred Seat (will be shown first when you want to reserve a seat in an office)
        </v-card-title>

        <v-form @submit.prevent="submit" ref="form">

            <v-data-table
                disable-pagination 
                :headers="headers"
                :items="offices"
                :hide-default-footer="true"
                :loading="loading"
                group-by="company"
                hide-default-header
            >
            
                <template slot="group.header" slot-scope="data">
                    <td colspan="2"><b>{{ data.group }}</b></td>
                </template>

                <template v-slot:[`item.seat`]="{ item }">
                    <v-select outlined dense hide-details
                        :items="generateSeats(item.seats)"
                        v-model="item.seat"
                        label="Preferred seat"
                        class="mt-3 mb-2"
                        prepend-icon="mdi-seat"
                    />                                
                </template>

            </v-data-table>

            <v-btn color="success" type="submit" :loading="loading2" class="mt-5">
                <v-icon left dark>mdi-check</v-icon>
                Save Changes
            </v-btn>

        </v-form>

    </v-card>

</template>

<script>

export default {
    
    data() {
        return {
            loading: true,
            loading2: false,
            headers: [{
                text: "Office",
                value: "office",
                filterable: false, 
                sortable: false
            },
            {
                text: "Company",
                value: "company",
                filterable: false, 
                sortable: false
            },
            {
                text: "Preferred seat",
                value: "seat",
                filterable: false, 
                sortable: false,
                align: "right",
                width: 400
            }],
            offices: []
        }
    },

    async created() {

        this.loading = true;
        
        let response = await fetch(process.env.VUE_APP_API + "/office/list");
        
        const data = await response.json();

        for (let company of data) {

            let name = company.name;

            for (let office of company.offices) {
                
                this.offices.push({
                    office: office.name,
                    company: name,
                    seat: office.seat,
                    seats: office.seats,
                    id: office.id
                });
            }
        }



        this.loading = false;
    },

    methods: {

        generateSeats(total) {
            let seats = [];

            for (let i=1; i<=total; i++) {
                seats.push({
                    text: i,
                    value: i
                });
            }
            
            return seats;
        },

        async submit() {

            this.loading2 = true;

            let seats = [];
            for (let office of this.offices) {
                seats.push({
                    office: office.id,
                    seat: office.seat
                });
            }

            await fetch(process.env.VUE_APP_API + "/office/seat", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    seats: seats
                })
            });

            this.loading2 = false;

            this.$store.commit("showNotice", "Your changes has been saved");
        }
    }    
}

</script>
