<template>
    <v-card max-width="1300" class="mx-auto mb-3">

        <v-card-title>Invites</v-card-title>

        <v-card-text>

            <v-dialog v-model="dialog" persistent max-width="500" :retain-focus="false">
                <v-card>
                    <v-toolbar dark color="primary">
                        {{ selection.mail }}
                    </v-toolbar>

                    <v-card-text class="pt-5">Are you sure you want to remove this invite?</v-card-text>

                    <v-card-actions>
                    
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" text @click="dialog=false">Cancel</v-btn>
                        <v-btn text dark class="error" :loading="loading2" @click="removeInvite(selection)">Remove</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-data-table
                dense disable-pagination
                :hide-default-footer="true"
                :headers="headers"
                :items="users"
                :loading="loading"
                item-key="mail"
            >

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn color="error" x-small dark @click.stop="showConfirm(item)">Remove</v-btn>
                </template>

                <template slot="no-data">
                        No active invites at this time
                </template>                

            </v-data-table>

        </v-card-text>
    </v-card>
</template>

<script>

export default {
    
    data() {
        return {
            headers: [
                {
                    text: "E-mail",
                    value: "mail"
                },
                {
                    text: "Date Invited",
                    value: "date"
                },
                {
                    text: "Action",
                    value: "action",
                    filterable: false, 
                    sortable: false,
                    align: 'right'
                }
            ],
            users: [],

            loading: true,
            loading2: false,
            selection: {},
            dialog: false

        }
    },

    created() {
        this.reloadTable();
    },

    methods: {

        async reloadTable() {

            this.loading = true;
            
            //Fill invited tables
            const response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/invites");

            const data = await response.json();

            this.users = [];
            for (let user of data) {

                this.users.push({
                    mail: user.mail,
                    date: parseInt(((Date.now()/1000) - user.timestamp) / (3600*24)) + " day(s) ago"
                });
            }

            this.loading = false;

        },

        showConfirm(item) {
            this.selection = item;
            this.dialog = true;
        },
        
        async removeInvite(item) {

            this.loading2 = true;

            await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/invites/" + item.mail, {
                method: "DELETE",
            });

            //Remove item from table
            for (let index in this.users) {
                if (this.users[index].mail == item.mail) {
                    this.users.splice(index, 1);
                    break;
                }
            }

            this.loading2 = false;
            this.dialog = false;
        }

    }
}

</script>