<template>

    <span>
        <v-btn @click.stop="show=true" color="success">
            <v-icon left>mdi-plus</v-icon>
            Add Company
        </v-btn>

        <v-dialog max-width="500px" v-model="show">
            <v-form @submit.prevent="submit" ref="form">

                <v-card>

                    <v-toolbar dark color="primary">
                        <span>Add Company</span>

                        <v-spacer />

                        <v-btn icon @click="show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="mt-2 pb-0">

                        <v-text-field outlined dense v-model="name"
                            label="Company name"
                            :error-messages="errors.name"
                            @focus="errors.name=null"
                        />
                        
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="loading">Add Company</v-btn>
                    </v-card-actions>

                </v-card>
            </v-form>
        </v-dialog>

    </span>

</template>

<script>
export default {
    data() {
        return {
            show: false,
            name: null,
            loading: false,
            errors: {
                name: null
            }
        }
    },
    watch: {
        show(value) {

            //Clear form on dialog close
            if (!value) {
                this.errors = {
                    name: null
                };
                this.$refs.form.reset();
            }
        }
    },
    methods: {
        async submit() {

            this.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/company/create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    name: this.name
                })
            });

            const data = await response.json();

            this.loading = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.show = false;

            this.$emit("reloadOffices");
        }
    }
}
</script>