<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19" stroke="none" stroke-linecap="round" stroke-linejoin="round" fill="#fff" fill-rule="evenodd">
        <path d="M2 15.4v3h3v-3h10v3h3v-6H2v3m15-8h3v3h-3v-3m-17 0h3v3H0v-3m15 3H5v-8c0-1.1046.8954-2 2-2h6c1.1046 0 2 .8954 2 2v8z" fill="red" fill-rule="nonzero"/>
        <g fill="#000">
            <path d="M16.8 13.2812c-.0505-1.0275-.3018-3.2228-.5536-4.2033-.2524-1.1211-.5547-1.6814-1.4106-2.1019s-1.3096-.5603-1.6124-.5603c-.2519 0-.4028.1402-.6041.2801-.0504 0-.0504.0467-.101.0467-.8059.7005-1.6119.8407-2.4683.8407-.806 0-1.6119-.1867-2.4684-.8407-.0505 0-.0505-.0467-.1009-.0467-.1509-.1399-.3018-.2801-.6041-.2801s-.756.1402-1.6119.5603-1.1588.9808-1.4106 2.1019c-.2019.9805-.5036 3.1292-.5541 4.2033z"/>
            <path d="M14.1868 3.5797C14.1868 1.6014 12.3563 0 10.0992 0S6.0123 1.6014 6.0123 3.5797s1.8298 3.5794 4.0869 3.5794 4.0876-1.5545 4.0876-3.5794z"/>
        </g>
    </svg>
</template>

<script>

export default {
    name: 'ChairUnavailable',
}

</script>