<template>

    <div>

        <Navigation />

        <EditProfileForm />

        <DefaultWeekForm />

        <PreferredSeatForm />

    </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import DefaultWeekForm from '@/components/DefaultWeekForm';
import EditProfileForm from '@/components/EditProfileForm';
import PreferredSeatForm from '@/components/PreferredSeatForm';

export default {
    name: "AccountSettngs",
    components: {
        Navigation,
        DefaultWeekForm,
        EditProfileForm,
        PreferredSeatForm
    },

    metaInfo: {
      title: 'Profile'
    }

}
</script>