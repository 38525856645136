<template>

    <v-card max-width="1300" class="mx-auto mb-3">

        <OverlayLoader :show="loading" />

        <v-card-title>Edit Office</v-card-title>
        <v-card-text>
            <v-form @submit.prevent="submit" ref="form" enctype="multipart/form-data">

                <v-text-field outlined dense v-model="name"
                    label="Office name"
                    :error-messages="errors.name"
                    @focus="errors.name=null"
                />

                <v-text-field outlined dense v-model="location"
                    label="Location"
                    messages="Enter the location of the office, such as a city or a street address"
                    :error-messages="errors.location"
                    @focus="errors.location=null"
                />

                <v-select
                    outlined dense
                    :items="timezones"
                    v-model="timezone"
                    label="Timezone"
                    messages="Enter the timezone the office is located in"
                    item-value="value"
                    item-text="text"
                    :error-messages="errors.timezone"
                    @focus="errors.timezone=null"
                />

                <v-text-field outlined dense v-model="seats"
                    label="Seats"
                    messages="Enter the amount of reservable seats this office has"
                    :error-messages="errors.seats"
                    @focus="errors.seats=null"
                />

                <v-file-input
                    outlined dense
                    prepend-icon=""
                    accept="image/*"
                    show-size
                    :rules="rules"
                    label="Office seat map"
                    messages="Select a picture showing the office seat map. Max 10MB"
                    v-model="picture"
                />

                <v-img v-if="pictureURL"
                    class="mt-5"
                    max-height="340"
                    max-width="450"
                    :src="domain + '/pictures/' + pictureURL + '.450x0.jpg'"
                />

                <v-btn color="success" type="submit" :loading="loading2" class="mt-3">
                    <v-icon left dark>mdi-check</v-icon>
                    Save Changes
                </v-btn>
            </v-form>
        </v-card-text>
    </v-card>

</template>

<script>
import OverlayLoader from '@/components/OverlayLoader';

export default {
    components: {
        OverlayLoader
    },

    data() {
        return {
            domain: process.env.VUE_APP_URL,
            loading: true,
            loading2: false,
            name: null,
            location: null,
            seats: null,
            timezone: null,
            picture: null,
            pictureURL: null,
            errors: {},
            timezones: [
                { "value": -12, "text": "GMT-12 (IDLW)" },
                { "value": -11, "text": "GMT-11 (NT)" },
                { "value": -10, "text": "GMT-10 (HST)" },
                { "value": -9, "text": "GMT-9 (AKST)" },
                { "value": -8, "text": "GMT-8 (PST)" },
                { "value": -7, "text": "GMT-7 (MST)" },
                { "value": -6, "text": "GMT-6 (CST)" },
                { "value": -5, "text": "GMT-5 (EST)" },
                { "value": -4, "text": "GMT-4 (AST)" },
                { "value": -3, "text": "GMT-3 (ART)" },
                { "value": -2, "text": "GMT-2 (AT)" },
                { "value": -1, "text": "GMT-1 (WAT)" },
                { "value": 0, "text": "GMT" },
                { "value": 1, "text": "GMT+1 (CET)" },
                { "value": 2, "text": "GMT+2 (EET)" },
                { "value": 3, "text": "GMT+3 (MSK)" },
                { "value": 4, "text": "GMT+4 (AMT)" },
                { "value": 5, "text": "GMT+5 (PKT)" },
                { "value": 6, "text": "GMT+6 (OMSK)" },
                { "value": 7, "text": "GMT+7 (KRAT)" },
                { "value": 8, "text": "GMT+8 (CST)" },
                { "value": 9, "text": "GMT+9 (JST)" },
                { "value": 10, "text": "GMT+10 (AEST)" },
                { "value": 11, "text": "GMT+11 (SAKT)" },
                { "value": 12, "text": "GMT+12 (NZST" }
            ],
            rules: [
                value => !value || value.size < 10000000
            ]

        }
    },

    async created() {

        //Fill edit form
        let response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id);

        let data = await response.json();

        this.name = data.office;
        this.location = data.location;
        this.seats = data.seats;
        this.timezone = data.timezone;
        this.pictureURL = data.picture;

        this.loading = false;

    },

    methods: {
        
        async submit() {

            this.loading2 = true;

            let response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    name: this.name,
                    location: this.location,
                    seats: this.seats,
                    timezone: this.timezone
                })
            });

            let data = await response.json();
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            if (this.picture) {
            
                //Upload seatmap picture
                response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/picture", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: this.picture
                });

                data = await response.json();

                this.pictureURL = data.picture;
            }

            this.picture = null;
            this.loading2 = false;

            this.$store.commit("showNotice", "Your changes has been saved");

        }

    }

}

</script>