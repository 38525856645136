var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navigation'),_c('h1',[_vm._v("Impressum / Kontakt")]),_vm._m(0),_c('br'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_c('br'),_c('br'),_c('v-divider',{attrs:{"id":"english"}}),_c('br'),_c('br'),_c('h2',[_vm._v("English Version")]),_c('h1',[_vm._v("Legal Notice / Contact")]),_c('br'),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48),_vm._m(49),_vm._m(50)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('a',{attrs:{"href":"#english"}},[_vm._v("English Version")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Informationen nach § 5 TMG")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Berus Ventures UG (haftungsbeschränkt)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Gärtnerstr. 10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("10245 Berlin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("E-Mail: hello@wunderoffice.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Website:")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://wunderoffice.com/"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v(" ")])]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"http://www.wunderoffice.com"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#1155cc","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"underline","-webkit-text-decoration-skip":"none","text-decoration-skip-ink":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("www.wunderoffice.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Vertreten durch den Geschäftsführer: Bernard Berus")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Gerichtsstand: Berlin, Registergericht: Amtsgericht Charlottenburg")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Registernummer: HRB 150536 B")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Umsatzsteuer-Identifikationsnummer: auf Nachfrage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Hinweis: Dieses Impressum gilt für alle von Berus Ventures UG (haftungsbeschränkt) betriebenen Websites, insbesondere für die oben genannte Website sowie den Blog und Unterseiten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticStyle:{"line-height":"1.38","margin-top":"18pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"17pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Haftungsausschluss")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Haftung für Inhalte")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Gemäß § 7 Abs.. 1 TMG sind wir als Diensteanbieter für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Haftung für Links")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Copyright")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Die Inhalte dieser Website unterliegen, soweit nicht anders angegeben, dem Urheberrecht und dürfen ohne vorherige schriftliche Zustimmung der Berus Ventures UG (haftungsbeschränkt) weder als Ganzes noch in Teilen verbreitet, verändert oder kopiert werden. Die in diese Website eingebundenen Bilder dürfen nicht ohne vorherige schriftliche Zustimmung der Berus Ventures UG (haftungsbeschränkt) verwendet werden. Die auf den Webseiten enthaltenen Bilder unterliegen teilweise dem Urheberrecht Dritter. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Blog der “Berus Ventures UG (haftungsbeschränkt)”")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Verantwortlich gemäß § 55 Abs. 2 RStV: Bernard Berus (Geschäftsführer)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Gärtnerstr. 10, 10245 Berlin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Deutschland")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("E-Mail: ")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"mailto:hello@wunderoffice.com"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#1155cc","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"underline","-webkit-text-decoration-skip":"none","text-decoration-skip-ink":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("hello@wunderoffice.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Information according to § 5 TMG (German Law)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Berus Ventures UG (haftungsbeschränkt)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Gärtnerstr. 10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("10245 Berlin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("E-Mail: hello@wunderoffice.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Website:")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://wunderoffice.com/"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v(" ")])]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"http://www.wunderoffice.com"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#1155cc","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"underline","-webkit-text-decoration-skip":"none","text-decoration-skip-ink":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("www.wunderoffice.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Represented by Managing Director: Bernard Berus")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Place of jurisdiction: Berlin, Register Court: Charlottenburg Local Court")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Register Number: HRB 150536 B")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("VAT identification number: on request")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Note: This imprint applies to all websites operated by Berus Ventures UG (haftungsbeschränkt) in particular the above mentioned website as well as the blog and subsites")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticStyle:{"line-height":"1.38","margin-top":"18pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"17pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Disclaimer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Liability for contents")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("The contents of our pages were created with greatest care. However, we cannot assume any guarantee for the correctness, completeness and topicality of the contents. According to § 7 Abs. 1 TMG as a service provider we are responsible for our own contents on these pages in accordance with the general laws. According to §§ 8 to 10 TMG, as a service provider we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this respect is only possible from the moment of knowledge of a concrete legal violation. As soon as we become aware of such infringements, we will remove these contents immediately.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Liability for links")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the websites is always responsible for the contents of the linked websites. The linked websites were checked for possible legal infringements at the time of linking. Illegal contents were not recognisable at the time of linking. However, a permanent content-related control of the linked websites is not reasonable without concrete evidence of a legal violation. We will remove such links immediately upon becoming aware of any legal violations.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Copyright")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"12pt","margin-bottom":"12pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("The contents of this website are subject to copyright, unless otherwise indicated, and may not be distributed, modified or copied as a whole or in part without the prior written consent of Berus Ventures UG (haftungsbeschränkt). The images integrated into this website may not be used without the prior written consent of Berus Ventures UG (haftungsbeschränkt). Images contained on the websites are partially subject to copyright of third parties. Insofar as the content on this website was not created by the operator, the copyrights of third parties are respected. In particular, any contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, we kindly ask for appropriate reference. As soon as we become aware of any legal violations, we will remove such content immediately.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticStyle:{"line-height":"1.38","margin-top":"14pt","margin-bottom":"4pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Berus Ventures UG (haftungsbeschränkt) Blog")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Responsible according to § 55 Abs. 2 RStV: Bernard Berus (Managing Director)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Gärtnerstr. 10, 10245 Berlin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Deutschland")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"line-height":"1.38","margin-top":"0pt","margin-bottom":"0pt"},attrs:{"dir":"ltr"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("E-Mail: ")]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"mailto:hello@wunderoffice.com"}},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Arial","color":"#1155cc","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"underline","-webkit-text-decoration-skip":"none","text-decoration-skip-ink":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("hello@wunderoffice.com")])])])
}]

export { render, staticRenderFns }