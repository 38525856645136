<template>
	<v-app>

		<v-main>
			
			<v-container fluid>
				<router-view></router-view>
			</v-container>

			<v-snackbar top v-model="$store.state.notice">
				{{ $store.state.notice_msg }}
			</v-snackbar>

		</v-main>

	</v-app>
</template>

<script>

export default {
	name: 'App'
};
</script>
