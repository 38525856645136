<template>

    <v-card class="pa-5 mx-auto" max-width="1300">

        <v-row>

            <v-col>

                <v-card-title>
                    <v-icon left>mdi-clipboard-account-outline</v-icon>
                    Personal details
                </v-card-title>

                <v-form @submit.prevent="submit" ref="form">

                    <v-text-field outlined dense v-model="firstname"
                        label="First name"
                        :error-messages="errors.firstname"
                        @focus="errors.firstname=null"
                    />

                    <v-text-field outlined dense v-model="lastname" 
                        label="Last name"
                        :error-messages="errors.lastname"
                        @focus="errors.lastname=null"
                    />

                    <v-text-field outlined dense v-model="title" 
                        label="Work title"
                        :error-messages="errors.title"
                        @focus="errors.title=null"
                    />

                    <v-btn color="success" type="submit" :loading="loading2" class="mr-3">
                        <v-icon left dark>mdi-check</v-icon>
                        Save Changes
                    </v-btn>

                    <PasswordPopup />

                </v-form>

            </v-col>

            <v-col cols="3" align="center">

                <v-avatar size=256>
                    <v-icon size=256 v-if="!$store.state.session.picture">mdi-account-circle</v-icon>
                    <img :src="domain + '/pictures/' + $store.state.session.picture + '.256x256.jpg'" v-if="$store.state.session.picture" style="border: 1px solid black" />
                </v-avatar>
                
                <UploadProfilePicture />

            </v-col>

        </v-row>

    </v-card>

</template>

<script>
import UploadProfilePicture from '@/components/UploadProfilePicture';
import PasswordPopup from '@/components/PasswordPopup';

export default {
    components: {
        UploadProfilePicture,
        PasswordPopup
    },

    data() {
        return {
            domain: process.env.VUE_APP_URL,
            loading: true,
            loading2: false,
            firstname: this.$store.state.session.firstname,
            lastname: this.$store.state.session.lastname,
            title: this.$store.state.session.title,
            errors: {
                firstname: null,
                lastname: null,
                title: null
            }
        }
    },

    methods: {
        async submit() {

            this.loading2 = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/profile", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    firstname: this.firstname,
                    lastname: this.lastname,
                    title: this.title
                })
            });

            const data = await response.json();

            this.loading2 = false;

            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.$store.commit("session", {
                firstname: this.firstname,
                lastname: this.lastname,
                title: this.title
            });

            this.$store.commit("showNotice", "Your changes has been saved");
        }
    }
}
</script>
