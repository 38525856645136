<template>

    <v-overlay absolute="absolute" :value="show">
        <v-progress-circular indeterminate size="64" />
    </v-overlay>

</template>

<script>

export default {
    props: [ "show" ]
}
</script>
