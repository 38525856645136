<template>

    <span>
        <v-btn @click.stop="show=true" color="success">
            <v-icon left>mdi-domain-plus</v-icon>
            Add Office
        </v-btn>

        <v-dialog max-width="500px" v-model="show">
            <v-form @submit.prevent="submit" ref="form">

                <v-card>
                    <v-toolbar dark color="primary">
                        <span>Add Office</span>

                        <v-spacer />

                        <v-btn icon @click="show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="mt-2 pb-0">

                        <v-text-field outlined dense v-model="name" 
                            label="Office name"
                            :error-messages="errors.name"
                            @focus="errors.name=null"
                        />

                        <v-text-field outlined dense v-model="location" 
                            label="Location"
                            messages="Enter the location of the office, such as a city or a street address"
                            :error-messages="errors.location"
                            @focus="errors.location=null"
                        />

                        <v-text-field outlined dense v-model="seats" 
                            label="Seats"
                            messages="Enter the amount of reservable seats this office has"
                            :error-messages="errors.seats"
                            @focus="errors.seats=null"
                        />

                        <v-select
                            outlined dense
                            :items="timezones"
                            v-model="timezone"
                            label="Timezone"
                            messages="Enter the timezone the office is located in"
                            item-value="value"
                            item-text="text"
                            :error-messages="errors.timezone"
                            @focus="errors.timezone=null"
                        />

                        
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="loading" class="mt-3">Add Office</v-btn>
                    </v-card-actions>

                </v-card>
            </v-form>

        </v-dialog>

    </span>

</template>

<script>
export default {
    data() {
        return {
            show: false,
            name: null,
            location: null,
            seats: null,
            timezone: function() {
                return new Date().getTimezoneOffset() / 60 * -1;
            }(),
            loading: false,
            errors: {
                name: null,
                location: null,
                seats: null,
                timezone: null
            },
            timezones: [
                { "value": -12, "text": "GMT-12 (IDLW)" },
                { "value": -11, "text": "GMT-11 (NT)" },
                { "value": -10, "text": "GMT-10 (HST)" },
                { "value": -9, "text": "GMT-9 (AKST)" },
                { "value": -8, "text": "GMT-8 (PST)" },
                { "value": -7, "text": "GMT-7 (MST)" },
                { "value": -6, "text": "GMT-6 (CST)" },
                { "value": -5, "text": "GMT-5 (EST)" },
                { "value": -4, "text": "GMT-4 (AST)" },
                { "value": -3, "text": "GMT-3 (ART)" },
                { "value": -2, "text": "GMT-2 (AT)" },
                { "value": -1, "text": "GMT-1 (WAT)" },
                { "value": 0, "text": "GMT" },
                { "value": 1, "text": "GMT+1 (CET)" },
                { "value": 2, "text": "GMT+2 (EET)" },
                { "value": 3, "text": "GMT+3 (MSK)" },
                { "value": 4, "text": "GMT+4 (AMT)" },
                { "value": 5, "text": "GMT+5 (PKT)" },
                { "value": 6, "text": "GMT+6 (OMSK)" },
                { "value": 7, "text": "GMT+7 (KRAT)" },
                { "value": 8, "text": "GMT+8 (CST)" },
                { "value": 9, "text": "GMT+9 (JST)" },
                { "value": 10, "text": "GMT+10 (AEST)" },
                { "value": 11, "text": "GMT+11 (SAKT)" },
                { "value": 12, "text": "GMT+12 (NZST" }
            ]
        }
    },
    props: [
        "company"
    ],
    watch: {
        show(value) {

            //Clear form on dialog close
            if (!value) {
                this.errors = {
                    name: null,
                    location: null,
                    timezone: null,
                    seats: null
                };
                this.$refs.form.reset();
            }
        }
    },
    methods: {
        async submit() {

            this.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/office/create", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    company: this.company,
                    name: this.name,
                    location: this.location,
                    timezone: this.timezone,
                    seats: this.seats
                })
            });

            const data = await response.json();

            this.loading = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.show = false;

            this.$emit("reloadOffices");
        }
    }
}
</script>