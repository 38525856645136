<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:v="http://vecta.io" width="20" height="18" viewBox="0 0 20 18">
    <g><g ><g>
    <path d="M 2 15 L 2 18 L 5 18 L 5 15 L 15 15 L 15 18 L 18 18 L 18 12 L 2 12 L 2 15 M 17 7 L 20 7 L 20 10 L 17 10 L 17 7 M 0 7 L 3 7 L 3 10 L 0 10 L 0 7 M 15 10 L 5 10 L 5 2 C 5 0.8954 5.8954 0 7 0 L 13 0 C 14.1046 0 15 0.8954 15 2 L 15 10 Z" fill="#BDBDBD" fill-rule="nonzero" stroke="none" stroke-linecap="butt" stroke-linejoin="miter" stroke-width="1"/></g>
    <g transform="translate(4.7569 0.1231)">
    </g></g></g></svg>
</template>

<script>

export default {
    name: 'ChairUnreserved',
}

</script>