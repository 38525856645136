<template>

    <div>

        <v-btn @click.stop="show=true" color="success" class="mt-3">Upload Picture</v-btn>

        <v-dialog max-width="500px" v-model="show">

            <v-form @submit.prevent="submit" ref="form">

                <v-card>
                    <v-toolbar dark color="primary">
                        <span>Upload picture</span>

                        <v-spacer />

                        <v-btn icon @click="show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="mt-2">
                            
                        <v-image-input
                            v-model="picture"
                            :image-quality="0.85"
                            image-format="jpeg"
                            hide-actions
                            full-width
                        />
                        
                    </v-card-text>

                    <v-card-actions class="pl-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" :loading="loading" class="mr-3">Upload Picture</v-btn>
                    </v-card-actions>

                </v-card>
            </v-form>
        </v-dialog>

    </div>

</template>

<script>
import VImageInput from 'vuetify-image-input/a-la-carte'

export default {
    components: {
        VImageInput
    },
    data() {
        return {
            show: false,
            picture: null,
            loading: false
        }
    },
    methods: {
        async submit() {

            this.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/picture", {
                method: "POST",
                body: this.picture
            });

            const data = await response.json();
            
            this.loading = false;
            this.show = false;

            if (!response.ok) return;
            
            this.$store.commit('session', {
                picture: data.picture
            });

            this.$store.commit("showNotice", "Your profile picture has been updated");
        }
    }
}
</script>