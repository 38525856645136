<template>
    <div>

        <Navigation />

        <v-card max-width="1300" class="mx-auto mb-3">

            <v-toolbar flat>

                <v-toolbar-title>Manage offices</v-toolbar-title>

                <v-spacer></v-spacer>

                <CompanyCreatePopup @reloadOffices="reloadOffices" />

            </v-toolbar>

        </v-card>

        <CompaniesList :reload="reload" />

    </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import CompanyCreatePopup from '@/components/CompanyCreatePopup';
import CompaniesList from '@/components/CompaniesList';

export default {
    name: "CompanyOffices",

    metaInfo: {
      title: 'Offices'
    },

    components: {
        Navigation,
        CompanyCreatePopup,
        CompaniesList
    },

    data() {
        return {
            reload: false
        }
    },

    methods: {

        reloadOffices() {
            this.reload = !this.reload;
        }
    }
}
</script>
