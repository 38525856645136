<template>
    <div>

        <Navigation />

        <v-alert v-if="!this.$store.state.session.week"
            max-width="1300" 
            class="mx-auto"
            border="bottom"
            colored-border
            type="warning"
            elevation="2"
        >
            Please first go to “<router-link to="/profile">My work profile</router-link>” and tell us how your typical work week looks like. These weekly repeating selections will be shown with a grey color. In your calendar you will have to click on a grey icon & confirm it so that it will appear with a black icon to your colleagues (which means you confirmed it).
        </v-alert>

        <v-alert v-if="!this.$store.state.session.offices"
            max-width="1300" 
            class="mx-auto"
            border="bottom"
            colored-border
            type="warning"
            elevation="2"
        >
            It seems you are not connected to an office yet. Please go to “<router-link to="/company/offices">Manage offices</router-link>” in order to setup a company office (<a href="https://sites.google.com/view/wunderoffice-setup-guide/home" target="_blank">Setup guide</a>) or contact your HR or office manager and ask them to add you to an existing office.
        </v-alert>

        <v-card class="pa-3 mx-auto" max-width="1300">

            <v-toolbar flat>

                <v-spacer></v-spacer>

                <v-badge
                    bordered
                    dot
                    color="green"
                    overlap
                    offset-x="17"
                    offset-y="6"
                >

                    <v-btn @click.stop="setDate()" color="success" class="mr-3" :disabled="!enableBack">
                        <v-icon left>mdi-calendar-today</v-icon>
                        Today
                    </v-btn>

                </v-badge>

                <v-btn @click.stop="setDate(-14)" color="success" class="mr-3" :disabled="!enableBack">
                    <v-icon>mdi-undo</v-icon>
                </v-btn>

                <v-btn @click.stop="setDate(14)" color="success">
                    <v-icon>mdi-redo</v-icon>
                </v-btn>

            </v-toolbar>

            <MyScheduleTable :currentDate="currentDate" @updateSeats="updateSeats" ref="MyScheduleTable" />

            <AvailabilityTable :currentDate="currentDate" ref="AvailabilityTable" />

            <ScheduleTable :currentDate="currentDate" ref="ScheduleTable" />

        </v-card>

    </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import AvailabilityTable from '@/components/AvailabilityTable'
import ScheduleTable from '@/components/ScheduleTable'
import MyScheduleTable from '@/components/MyScheduleTable'

export default {
    name: "Dashboard",
    components: {
        Navigation,
        AvailabilityTable,
        ScheduleTable,
        MyScheduleTable
    },

    metaInfo: {
      title: 'Dashboard'
    },

    data() {
        return {
            enableBack: false,
            currentDate: new Date()
        }
    },
        
    methods: {
        
        setDate(days) {
            if (days) this.currentDate.setDate(this.currentDate.getDate()+days);
            else this.currentDate.setTime(new Date().getTime());

            if (new Date().getTime() > this.currentDate.getTime()-3600*24) this.enableBack = false;
            else this.enableBack = true;

            //Update all 3 tables
            this.$refs.MyScheduleTable.loadSchedule();
            this.$refs.AvailabilityTable.loadOffices();
            this.$refs.ScheduleTable.loadSchedule();
        },

        //Update table cell for increasing/decreasing seats
        updateSeats(office, index, value) {
            this.$refs.AvailabilityTable.updateSeats(office, index, value);
        }

    }
}
</script>