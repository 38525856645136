<template>

    <div>
        <v-dialog max-width="485px" v-model="show">

            <v-form @submit.prevent="submit" ref="form">

                <v-card>

                    <v-toolbar dark color="primary">
                        <span>{{ selected.weekday_text }}, {{ selected.day }} {{ selected.month_text }} {{ selected.year }}</span>

                        <v-spacer />

                        <v-btn icon @click="show=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-card-text class="mt-5">

                        <v-select
                            outlined dense
                            :items="options"
                            v-model="value"
                            label="How will you spend the day"
                            :loading="loading2"
                            :error-messages="errors.value"
                            @focus="errors.value=null"
                            @change="valueChange"
                        >

                            <template v-slot:prepend>
                                <v-icon color="black">{{ icon }}</v-icon>
                            </template>

                            <template slot="item" slot-scope="data">
                                <v-icon left color="black">{{ data.item.icon }}</v-icon>  {{ data.item.text }}
                            </template>

                        </v-select>

                        <div v-if="showRange">
                            OPTIONAL: Pick a date range to apply on multiple dates.
                            <v-date-picker 
                                v-model="dates"
                                range
                                no-title
                                show-current=false
                                elevation="1"
                                :min="new Date().toISOString().slice(0,10)"
                            />
                        </div>

                        <div v-if="showSeats">

                            <v-select
                                outlined dense
                                :items="seats"
                                v-model="seat"
                                label="Seat"
                                messages="Specify the exact seat you want to reserve"
                                :loading="loading4"
                                :error-messages="errors.seat"
                                @focus="errors.seat=null"
                            >

                                <template slot="item" slot-scope="data">
                                    <v-icon left v-if="data.item.mail">$chair-unavailable</v-icon>
                                    <v-icon left color="success" v-if="!data.item.mail">mdi-seat</v-icon>
                                    {{ data.item.text }} - {{ data.item.mail }}
                                </template>

                            </v-select>

                            <v-dialog v-model="fullpicture" width="1100" v-if="picture">

                                <template v-slot:activator="{ on, attrs }">

                                    <v-img
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mt-5"
                                        max-width="450"
                                        :src="domain + '/pictures/' + picture + '.450x0.jpg'"
                                    >

                                        <v-btn
                                            absolute
                                            color="grey"
                                            class="white--text"
                                            right
                                            top
                                        >
                                            <v-icon>mdi-fullscreen</v-icon>
                                        </v-btn>

                                    </v-img>

                                </template>

                                <v-img
                                    max-width="1100"
                                    :src="domain + '/pictures/' + picture + '.1100x0.jpg'"
                                />

                            </v-dialog>

                        </div>

                            
                    </v-card-text>

                    <v-card-actions class="pr-4 pb-5">
                        <v-spacer />
                        <v-btn color="success" type="submit" class="mt-3" :loading="loading3">
                            <v-icon left dark>mdi-check</v-icon>
                            Confirm
                        </v-btn>
                    </v-card-actions>

                </v-card>

            </v-form>

        </v-dialog>

        <v-data-table disable-pagination 
            :headers="headers"
            :items="users"
            :loading="loading"
            :hide-default-footer="true"
        >
            
            <template v-slot:[`item.date_${index-1}`]="{ item }" v-for="index in 14">
                <ScheduleIcon :item="item[`date_${index-1}`]" :key="index" @showPopup="showPopup" />
            </template>

            <template v-slot:[`item.picture`]="{ item }">
                <v-avatar size="35">
                    <v-icon size="35" v-if="!item.picture">mdi-account-circle</v-icon>
                    <img :src="domain + '/pictures/' + item.picture + '.35x35.jpg'" v-if="item.picture" />
                </v-avatar>
            </template>

            <template v-slot:[`item.star`]="{ item }">
                <span v-if="item.uid && item.uid != $store.state.session.id">
                    <v-icon>mdi-star-outline</v-icon>
                </span>
            </template>

            <template v-slot:[`header.date_${index-1}`]="{ header }" v-for="index in 14">
                <span :key="index">
                    <v-badge v-if="new Date().getDate() + '.' + (new Date().getMonth()+1) == header.text.dayMonth"
                        bordered
                        dot
                        color="green"
                        overlap
                        offset-x="0"
                        offset-y="0"
                    >
                        <span>{{ header.text.dayMonth }} <br /> <b>{{ header.text.weekDay }}</b></span>
                    </v-badge>
                    <span v-else>{{ header.text.dayMonth }} <br /> <b>{{ header.text.weekDay }}</b></span>
                </span>
                
            </template>

            <template slot="no-data">
                    Please join an office to see your co-workers schedule here
            </template>

        </v-data-table>
    </div>

</template>

<script>
import ScheduleIcon from '@/components/ScheduleIcon'

export default {
    components: {
        ScheduleIcon
    },
    
    props: [ "currentDate" ],

    data() {
        return {
            domain: process.env.VUE_APP_URL,
            show: false,
            headers: [],
            users: [],
            loading: true,
            loading2: false,
            loading3: false,
            selected: {},
            value: null,
            options: [],
            errors: {
                value: null,
                seat: null
            },
            showRange: false,
            dates: [],
            showSeats: false,
            seat: null,
            seats: [],
            loading4: false,
            picture: null,
            icon: null,
            fullpicture: false
        }
    },

    created() {
        this.loadSchedule();
    },

    watch: {
        show(value) {

            //Clear form on dialog close
            if (!value) {
                this.errors = {
                    value: null,
                };
                this.$refs.form.reset();

                this.showRange = false;
                this.dates = [];

                this.showSeats = false;
                this.seat = null;
                this.seats = [];
            }
        }
    },

    methods: {

        async valueChange() {

            this.showRange = false;
            this.dates = [];

            this.showSeats = false;
            this.seat = null;
            this.seats = [];

            //Change icon
            this.icon = this.$getIconActive(this.value);
            
            if (this.value == -4 || this.value == -5) {
                this.dates = [this.selected.date];
                this.showRange = true;
            }

            if (this.value < 1) return;
            
            this.showSeats = true;
            this.loading4 = true;
            
            const response = await fetch(process.env.VUE_APP_API + "/office/" + this.value + "/seats/" + this.selected.date);

            const data = await response.json();

            //Fill drop down with seat options
            for (let index in data.seats) {

                this.seats.push({
                    value: index,
                    text: index,
                    ... data.seats[index]
                });
            }

            this.seat = (data.reserved > 0) ? data.reserved.toString() : data.preferred.toString();
            this.picture = data.picture;

            this.loading4 = false;

        },
        
        async submit() {

            this.loading3 = true;

            const response = await fetch(process.env.VUE_APP_API + "/schedule/set", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    date: (this.dates.length > 1) ? this.dates : this.selected.date,
                    value: this.value,
                    seat: this.seat
                })
            });

            const data = await response.json();
        
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                this.loading3 = false;

                return;
            }

            let office = {};
            if (this.value > 0) {
                //Load office details from API
                let response = await fetch(process.env.VUE_APP_API + "/office/" + this.value);
                
                office = await response.json();
            }

            //Update old office seat number (if exist)
            if (this.selected.value > 0) {
                this.$emit('updateSeats', this.selected.value, this.selected.index, -1);
            }

            if (this.dates.length == 0) this.dates.push(this.selected.date);
            if (this.dates.length == 1) this.dates.push(this.dates[0]);

            let end = new Date(this.dates[1]);
            for (let date = new Date(this.dates[0]); date <= end; date.setDate(date.getDate() + 1)) {
                for (let i=0; i<14; i++) {
                    if (this.users[0]["date_"+i].date == date.toISOString().slice(0,10)) {

                        this.users[0]["date_"+i] = {
                            ... this.users[0]["date_"+i],
                            default: false,
                            value: this.value,
                            seat: this.seat,
                            office: office
                        }

                        break;
                    }
                }

            }

            //Update new office seat number (if exist)
            if (this.value > 0) {
                this.$emit('updateSeats', this.value, this.selected.index, 1)
            }
            
            this.loading3 = false;
            this.show = false;

        },

        async showPopup(item) {

            this.selected = item;
            this.icon = null;
            this.picture = null;
            
            this.show = true;
            this.loading2 = true;

            //Load all offices this user belongs to
            let response = await fetch(process.env.VUE_APP_API + "/office/list");
            
            const data = await response.json();

            this.options = [
                { value: -1, text: "Working from home", icon: this.$getIconActive(-1) },
                { value: -2, text: "Traveling (hard to reach)", icon: this.$getIconActive(-2) },
                { value: -3, text: "Not working", icon: this.$getIconActive(-3) },
                { value: -4, text: "Sick leave", icon: this.$getIconActive(-4) },
                { value: -5, text: "Vacation leave", icon: this.$getIconActive(-5) }
            ];

            for (let company of data) {
                
                if (company.offices.length == 0) continue;

                for (let office of company.offices) {
                    this.options.push(
                        { value: office.id, text: "Working at " + company.name + " (" + office.name + ")", icon: this.$getIconActive(1) }
                    );
                }

            }

            this.value = this.selected.value;
            this.loading2 = false;

            this.valueChange();
        },
        
        //Update table cell for schedule change
        updateUser(index, value) {

            this.users[0]["date_" + index] = { 
                ... this.users[0]["date_" + index],
                ... value
            };
        },

        dateFormat(date) {
            return date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate();
        },

        //Load user schedules and office availability
        async loadSchedule() {

            this.loading = true;

            //Create table headers
            let headers = [{
                text: "",
                value: "star",
                filterable: false, 
                sortable: false,
                align: "center",
                width: "70"
            },
            {
                text: "",
                value: "picture",
                filterable: false, 
                sortable: false,
                width: "60"
            },
            {
                text: "",
                align: "start",
                value: "fullname",
                filterable: false, 
                sortable: false
            }];

            let weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

            let date = new Date(this.currentDate.getTime());

            for (let i=0; i<=13; i++) {

                let weekDay = weekDays[date.getDay()];
                let dayMonth = date.getDate() + "." + (date.getMonth()+1);
                //let fullDate = dayMonth + " " + weekDay;
                
                headers.push({ text: { weekDay: weekDay.toUpperCase(), dayMonth: dayMonth }, width: "58", value: "date_" + i, filterable: false, sortable: false, align: "center" });

                date.setDate(date.getDate()+1);
            }

            //Load all coworker schedules
            let response = await fetch(process.env.VUE_APP_API + "/schedule/list", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    date: this.dateFormat(this.currentDate)
                })
            });

            let data = await response.json();

            this.headers = headers;
            this.users = [];

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            
            for (let user of data) {
                
                let schedule = [];
                let index = 0;
                for (let day of user.schedule) {

                    schedule["date_" + index] = {
                        index: index,
                        uid: user.id,
                        month_text: months[day.month-1],
                        weekday_text: weekdays[day.weekday-1],
                        ... day
                    }

                    index++;
                }
                
                let row = {
                    fullname: (user.firstname || user.lastname) ? user.firstname + " " + user.lastname : user.mail,
                    uid: user.id,
                    picture: user.picture,
                    ... schedule
                }

                this.users.push(row);
            }

            this.loading = false;

        }

    }

}
</script>

<style scoped>
::v-deep td, ::v-deep th {
    padding: 0 !important;
}

::v-deep th {
    border-bottom: thin solid #000 !important;
    line-height: 1 !important;
}

</style>