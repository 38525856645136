<template>
    
    <v-card max-width="1300" class="mx-auto mb-8">

        <v-toolbar flat>

            <v-toolbar-title>Colleagues</v-toolbar-title>

            <v-spacer></v-spacer>

            <InvitePopup @reloadTables="$emit('reloadTables')" />

        </v-toolbar>

        <v-card-text>

            <v-dialog v-model="dialog" persistent max-width="500" :retain-focus="false">
                <v-card>
                    <v-toolbar dark color="primary">
                        {{ selection.mail }}
                    </v-toolbar>

                    <v-card-text class="pt-5">Are you sure you want to remove this colleague?</v-card-text>

                    <v-card-actions>
                    
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" text @click="dialog=false">Cancel</v-btn>
                        <v-btn text dark class="error" :loading="loading2" @click="removeUser(selection)">Remove</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-data-table
                dense disable-pagination
                :hide-default-footer="true"
                :headers="headers"
                :items="users"
                :loading="loading"
                item-key="mail"
            >

                <template v-slot:[`item.picture`]="{ item }">
                    <v-avatar size="35" v-if="item.picture">
                        <img :src="domain + '/pictures/' + item.picture + '.35x35.jpg'" v-if="item.picture" />
                    </v-avatar>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn color="error" x-small dark @click.stop="showConfirm(item)">Remove</v-btn>
                </template>

                <template slot="no-data">
                        No colleagues belong to this office yet
                </template>                

            </v-data-table>

        </v-card-text>
    </v-card>

</template>

<script>
import InvitePopup from '@/components/InvitePopup';

export default {
    components: {
        InvitePopup
    },

    data() {
        return {
            domain: process.env.VUE_APP_URL,
            dialog: false,
            loading: true,
            loading2: false,
            selection: {},

            headers: [
                {
                    text: "",
                    value: "picture"
                },
                {
                    text: "Full Name",
                    value: "name"
                },
                {
                    text: "E-mail",
                    value: "mail"
                },
                {
                    text: "Job Title",
                    value: "title"
                },
                {
                    text: "Action",
                    value: "action",
                    filterable: false, 
                    sortable: false,
                    align: "right"
                }

            ],
            users: []

        }
    },

    created() {
        this.reloadTable();
    },

    methods: {

        async reloadTable() {

            this.loading = true;

            //Fill workers table
            const response = await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/users");

            const data = await response.json();
            
            this.users = [];
            for (let user of data) {

                this.users.push({
                    id: user.id,
                    picture: user.picture,
                    name: user.firstname + " " + user.lastname,
                    mail: user.mail,
                    title: user.title
                });
            }

            this.loading = false;

        },

        showConfirm(item) {
            this.selection = item;
            this.dialog = true;
        },

        async removeUser(item) {

            this.loading2 = true;

            await fetch(process.env.VUE_APP_API + "/office/" + this.$route.params.id + "/users/" + item.id, {
                method: "DELETE",
            });

            //Remove item from table
            for (let index in this.users) {
                if (this.users[index].id == item.id) {
                    this.users.splice(index, 1);
                    break;
                }
            }

            this.loading2 = false;
            this.dialog = false;
        }

    }

}
</script>
