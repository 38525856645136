<template>

    <span>
        <v-btn @click.stop="show=true" large text class="mx-1">Sign in</v-btn>

        <v-dialog max-width="500px" v-model="show">

                <v-form @submit.prevent="submit" ref="form">

                    <v-card>

                        <v-toolbar dark color="primary">
                            <span>Sign in</span>

                            <v-spacer />

                            <v-btn icon @click="show=false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>

                        </v-toolbar>

                        <v-card-text class="pb-0 mt-3">

                                <GoogleLogin />
                                <FacebookLogin />

                            <v-divider class="mb-5" />

                            <h2 class="mb-5">... or sign in manually</h2>

                            <v-text-field outlined dense label="E-mail address" v-model="mail" prepend-icon="mdi-mail" :error-messages="errors.mail" @focus="errors.mail=null" />

                            <v-text-field outlined dense v-model="password" prepend-icon="mdi-key" 
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                                :type="showPassword ? 'text' : 'password'" 
                                label="Password"
                                :error-messages="errors.password"
                                @focus="errors.password=null"
                                @click:append="showPassword = !showPassword" />

                        </v-card-text>

                        <v-card-actions class="pr-4 pb-5">

                            <v-spacer />
                            <ForgotPopup />
                            <v-btn color="success" type="submit" :loading="loading" class="ml-3">Sign in</v-btn>

                        </v-card-actions>

                    </v-card>

                </v-form>

        </v-dialog>

    </span>


</template>

<script>
import ForgotPopup from '@/components/ForgotPopup'
import GoogleLogin from '@/components/GoogleLogin'
import FacebookLogin from '@/components/FacebookLogin'

export default {
    components: {
        ForgotPopup,
        GoogleLogin,
        FacebookLogin
    },

    props: [ "reset" ],

    data() {
        return {
            show: false,
            mail: null,
            password: null,
            showPassword: false,
            loading: false,
            errors: {
                mail: null,
                password: null
            }
        }
    },

    watch: {
        show(value) {

            //Clear form on dialog close
            if (!value) {
                this.errors = {
                    mail: null,
                    password: null
                };
                this.$refs.form.reset();
            }
        }
    },

    mounted() {
        this.show = this.reset;
    },

    methods: {
        async submit() {

            this.loading = true;

            const response = await fetch(process.env.VUE_APP_API + "/account/login", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    mail: this.mail,
                    password: this.password
                })
            });

            const data = await response.json();

            this.loading = false;
            
            if (data.errors) {
                data.errors.forEach(function(field) {
                    this.errors[field.name] = field.message;
                }, this);

                return;
            }

            this.show = false;

            //Save session to local cache
            this.$store.commit("session", data);

            this.$router.push("/dashboard");
        }
    }    
}
</script>
