<template>
    <div>

        <Navigation />

        <EditOfficeForm />

        <InviteTable @reloadTables="reloadTables" ref="InviteTable" />

        <OfficeWorkersTable @reloadTables="reloadTables" ref="OfficeWorkersTable" />

    </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import InviteTable from '@/components/InviteTable';
import EditOfficeForm from '@/components/EditOfficeForm';
import OfficeWorkersTable from '@/components/OfficeWorkersTable';

export default {
    name: "EditOffice",

    metaInfo: {
      title: 'Edit Office'
    },

    components: {
        Navigation,
        InviteTable,
        EditOfficeForm,
        OfficeWorkersTable
    },

    methods: {

        reloadTables() {
            this.$refs.InviteTable.reloadTable();
            this.$refs.OfficeWorkersTable.reloadTable();
        }

    }
}
</script>
