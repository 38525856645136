import Vue from 'vue'
import VueRouter from 'vue-router'

//Import all the views/pages used
import Index from '@/views/Index'
import IndexGerman from '@/views/IndexGerman'
import Dashboard from '@/views/Dashboard'
import Profile from '@/views/Profile'
import CompanyOffices from '@/views/CompanyOffices'
import EditOffice from '@/views/EditOffice'
import Contact from '@/views/Contact'
import Terms from '@/views/Terms'
import Privacy from '@/views/Privacy'

Vue.use(VueRouter)

/*
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    }
  ]
})
*/

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
      meta: {
        auth: 0
      }
    },
    {
      path: '/de/index',
      name: 'IndexGerman',
      component: IndexGerman,
      meta: {
        auth: 0
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        auth: 0
      }
    },
    {
      path: '/terms',
      name: 'Terms',
      component: Terms,
      meta: {
        auth: 0
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        auth: 0
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        auth: 1
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        auth: 1
      }
    },
    {
      path: '/company/offices',
      name: 'CompanyOffices',
      component: CompanyOffices,
      meta: {
        auth: 1
      }
    },
    {
      path: '/office/:id/edit',
      name: 'EditOffice',
      component: EditOffice,
      meta: {
        auth: 1
      }
    }
  ]
})